import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import ViewBoxContainer from "../Layout/ViewBoxContainer";
import pdfLogo from "../assets/images/pdf.png";
import { observer } from "mobx-react";
import { FSStoreInstance } from "../stores/FSstore";
import { userDataInstance } from "../stores/userDataStore";
import { useHistory } from "react-router-dom";
import { DefaultDownloadPageStuff } from "../utils/Consts";
import useUpdateChecker from "../customHooks/useUpdateCheck";

const DownloadPage = observer(() => {
    const { subject, chapter_name, chapter, set, type } = useParams();

    const history = useHistory();

    const fireEvent = (eventName, eventBody) =>
        FSStoreInstance.fireEvent(eventName, eventBody);
    const download = (link) => userDataInstance.downloadFromLink(link);

    const canDownload = useUpdateChecker(userDataInstance.canDownload);
    const rcValue = useUpdateChecker(FSStoreInstance.RCValue);
    const configValues = useUpdateChecker(FSStoreInstance.ConfigValues);
    const DownloadPageStuff = useUpdateChecker(FSStoreInstance.DownloadPage);
    const countRef = useRef();

    const startTimer = configValues
        ? configValues.paramsList.filter(
              (inst) => inst.desc === "timeToStartDownload"
          )[0].value || 5
        : 5;
    const backDelay = configValues
        ? configValues.paramsList.filter(
              (inst) => inst.desc === "downloadPageBackDelay"
          )[0].value || 10
        : 10;

    const downloadPageJsonArray =
        DownloadPageStuff !== undefined
            ? DownloadPageStuff
            : DefaultDownloadPageStuff;
    const max = downloadPageJsonArray.length - 1;
    const min = 0;
    const randomInd = Math.floor(Math.random() * (max - min + 1) + min);
    const selectedDownloadPageStuff = downloadPageJsonArray[randomInd];

    let timerId = undefined;
    let backTimerId = undefined;
    let downloadDone = false;

    const tmp = rcValue.files.filter((inst) => {
        if (
            inst.subject === subject &&
            inst.chapterInd === decodeURIComponent(chapter) &&
            inst.setInd === set &&
            inst.type === type
        ) {
            return inst;
        }
    });
    
    const noOfPassages = tmp[0].setMetaData.noOfPassage;
    const noOfQuestion = tmp[0].setMetaData.setData.reduce(
        (totalQuestions, inst) => totalQuestions + Number(inst.noOfQuestions),
        0
    );

    if (!canDownload && downloadDone) {
        history.push("/blocker");
    }

    const startCount = () => {
        if (countRef.current !== undefined) {
            backTimerId = setTimeout(() => {
                history.goBack();
            }, backDelay * 1000);

            timerId = setTimeout(function loop() {
                const currCount = countRef.current.innerHTML;

                if (currCount > 0) {
                    countRef.current.innerHTML = currCount - 1;
                    timerId = setTimeout(loop, 1000);
                } else {
                    let link = null;
                    rcValue.files.forEach((inst) => {
                        if (
                            inst.subject === subject &&
                            inst.chapterInd === chapter &&
                            inst.setInd === set &&
                            inst.type === type
                        ) {
                            link = inst.link;
                        }
                    });

                    if (link !== null) {
                        download(link);
                        downloadDone = true;
                    }
                    //downloadFile(subject,chapter,set,type);
                }
            }, 0);
        }
    };

    const stopCount = () => {
        clearInterval(timerId);
        clearInterval(backTimerId);
        console.log("stop count");
    };

    useEffect(() => {
        if (rcValue !== undefined) {
            if (canDownload) {
                startCount();

                //console.log(backDelay);
            }

            fireEvent("Download Page Opened", { subject, chapter, set, type });

            return () => {
                if (!downloadDone) {
                    stopCount();
                    console.log("Download stoped");
                    fireEvent("Download stoped", {
                        subject,
                        chapter,
                        set,
                        type,
                    });
                }
            };
        }
    }, [rcValue]);

    return (
        <ViewBoxContainer>
            <div
                style={{ height: "80vh" }}
                className="w-full flex flex-col pb-24 relative"
            >
                <div
                    style={{
                        backgroundColor: "#afafaf",
                        width: "80px",
                        height: "40px",
                        position: "absolute",
                        top: "12px",
                        left: "12px",
                    }}
                    className="text-white rounded-lg flex items-center justify-center"
                    onClick={() => {
                        history.goBack();
                    }}
                >
                    &lt;- Back
                </div>
                <div className="w-full flex flex-col items-center my-4">
                    <img
                        src={selectedDownloadPageStuff.image}
                        className="w-25 h-24 rounded-full my-2"
                    />
                    <p
                        style={{
                            color: "#fc4674",
                            minHeight: "50px",
                            fontSize: "16px",
                        }}
                        className=" font-bold w-6/12 text-center my-2"
                    >
                        {selectedDownloadPageStuff.title}
                    </p>
                    <p
                        style={{
                            minHeight: "50px",
                            fontSize: "12px",
                        }}
                        className="text-center text-gray-500 text-sm w-10/12 my-2"
                    >
                        {selectedDownloadPageStuff.body}
                    </p>
                    <a
                        style={{
                            backgroundColor: "#fc4674",
                            fontSize: "16px",
                        }}
                        className="px-4 py-2 text-white rounded-md"
                        target="_blank"
                        href={selectedDownloadPageStuff.cta_url}
                    >
                        {selectedDownloadPageStuff.cta}
                    </a>
                </div>

                <div
                    style={{
                        boxShadow: "0px -13px 16px #bdbdbd",
                        position: "absolute",
                        bottom: "0",
                    }}
                    className=" w-full bg-white pt-5 flex flex-col justify-center items-center  pb-5 mb-5"
                >
                    <p className="w-11/12 font-semibold text-gray-500">
                        Starting download in{" "}
                        <span ref={countRef}> {startTimer} </span>
                    </p>
                    <div className="w-11/12 bg-gray-200 flex items-center p-3 rounded-md my-1  ">
                        <img src={pdfLogo} className="w-12 h-12 my-1" />
                        <div>
                            <p className="text-lg font-semibold text-gray-500 my-1">
                                {type === "Answerkey"
                                    ? "Answer Key"
                                    : "Worksheet"}{" "}
                                {chapter}.{set}
                            </p>
                            <p className="text-sm text-gray-500">
                                {" "}
                                {noOfPassages} Passages{" "}
                            </p>
                            <p className="text-sm text-gray-500">
                                {" "}
                                {noOfQuestion} questions{" "}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </ViewBoxContainer>
    );
});

export default DownloadPage;
