import React, { useEffect, useRef } from "react";
import ViewBoxContainer from "../Layout/ViewBoxContainer";

const Test = () => {
    const data = {};

    useEffect(() => {
        throw new Error("intentional error");
    }, []);
    return (
        <ViewBoxContainer>
            <div>test</div>
        </ViewBoxContainer>
    );
};

export default Test;
