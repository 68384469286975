import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ViewBoxContainer from "../Layout/ViewBoxContainer";
import { FSStoreInstance } from "../stores/FSstore";
import eyeIcon from "../assets/images/eye_icon.png";
import maths from "../assets/images/maths.png";
import science from "../assets/images/science.png";
import english from "../assets/images/english.png";
import sst from "../assets/images/sst.png";

import getDownloadCount from "../utils/getDownloadCount";
import DownloadCounter from "../components/Common/DownloadCounter";
import useUpdateChecker from "../customHooks/useUpdateCheck";

const Home = observer(() => {
    const rcValue = useUpdateChecker(FSStoreInstance.RCValue);
    const fireEvent = (eventName, eventBody) =>
        FSStoreInstance.fireEvent(eventName, eventBody);
    const noOfQuestionAllSubject = FSStoreInstance.noOfQuestionAllSubject;
    const subjects = new Set();
    let subjectArray = [];
    let iconArray = [];
    if (rcValue !== undefined) {
        rcValue.files.map((inst) => {
            subjects.add(inst.subject);
        });
        subjectArray = Array.from(subjects);
        subjectArray = ["Maths", "Science", "English", "Social Studies"];
        iconArray = subjectArray.map((inst) => {
            switch (inst) {
                case "English":
                    return english;
                case "Maths":
                    return maths;
                case "Science":
                    return science;
                case "Social Studies":
                    return sst;
            }
        });
    }
    useEffect(() => {
        fireEvent("Home Page Opened", {});
    }, []);

    const colors = [
        {
            start: "#b884ff",
            end: "#594bc8",
        },
        {
            start: "#5ad6b5",
            end: "#42c6a3",
        },
        {
            start: "#fa7b7b",
            end: "#fc4674",
        },

        {
            start: "#4eb25a",
            end: "#369041",
        },
    ];

    return (
        <ViewBoxContainer>
            <div className="w-full pb-24 ">
                <DownloadCounter />
                <p
                    style={{ color: "#42c6a3" }}
                    className="font-normal text-lg font-semibold mx-4 mb-3"
                >
                    10th
                </p>
                <div className="w-full flex justify-center items-center">
                    <div
                        style={{}}
                        className="mx-4 w-full grid grid-cols-2 grid-rows-2 gap-4"
                    >
                        {rcValue !== undefined
                            ? colors.map((color, ind) => {
                                  const tmp = noOfQuestionAllSubject.filter(
                                      (inst) =>
                                          inst.subject === subjectArray[ind]
                                  )[0];
                                  const noOfQuestion = tmp
                                      ? tmp.noOfQuestions
                                      : 1234;

                                  return (
                                      <Link
                                          to={`/subject/${subjectArray[ind]}`}
                                          key={ind}
                                          className="w-full"
                                      >
                                          <div
                                              style={{
                                                  backgroundImage: `linear-gradient(to right bottom,${color.start},${color.end})`,
                                              }}
                                              className="rounded-lg w-full h-48 p-2 flex flex-col justify-center items-center text-center"
                                          >
                                              <div className="bg-white rounded-full p-4 flex justify-center items-center">
                                                  <img src={iconArray[ind]} />
                                              </div>
                                              <p className="text-white text-xl">
                                                  {subjectArray[ind] ===
                                                  "Social Studies"
                                                      ? "SST"
                                                      : subjectArray[ind]}
                                              </p>
                                              <p className="text-white text-xs">
                                                  {" "}
                                                  {noOfQuestion} questions
                                              </p>
                                          </div>
                                      </Link>
                                  );
                              })
                            : ""}
                    </div>
                </div>
            </div>
        </ViewBoxContainer>
    );
});

export default Home;
