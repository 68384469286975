//wrap this around every observable that is not directly rendered

//all derived values from a state variable are not changing when the state is changed in the store \
//because nirwal use "const var = storeinstance.var" and derives all values from var
//but var does not change when storeInstance.var is changed hence UI is not updated
//this hook is current work around to that

import React, { useEffect, useState } from "react";
const useUpdateChecker = (store) => {
    useEffect(() => {
        console.log("Update Detected");
    }, [store]);

    return store;
};

export default useUpdateChecker;
