import React, { useEffect } from "react";
import ViewBoxContainer from "../Layout/ViewBoxContainer";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { FSStoreInstance } from "../stores/FSstore";

import { Link } from "react-router-dom";
import DownloadCounter from "../components/Common/DownloadCounter";
import useUpdateChecker from "../customHooks/useUpdateCheck";

const SubjectsPage = observer(() => {
    let { subject } = useParams();

    const rcValue = useUpdateChecker(FSStoreInstance.RCValue);
    const fireEvent = (eventName, eventBody) =>
        FSStoreInstance.fireEvent(eventName, eventBody);
    const chapters = new Set();
    let chapterArray = [];
    let chapterJSONArray = [];

    if (rcValue !== undefined) {
        rcValue.files.map((inst) => {
            if (inst.subject === subject) {
                //stringify to omit duplicates
                chapters.add(
                    JSON.stringify({
                        name: inst.chapterName,
                        ind: inst.chapterInd,
                    })
                );
            }
        });
        chapterArray = Array.from(chapters);
        chapterArray.map((inst) => {
            chapterJSONArray.push(JSON.parse(inst));
        });
        chapterJSONArray.sort((a, b) => {
            if (parseInt(a.ind) > parseInt(b.ind)) {
                return 1;
            } else return -1;
        });
    }
    useEffect(() => {
        fireEvent("Subject Page Opened", { subject });
    }, []);
    return (
        <ViewBoxContainer>
            <div className="pb-24">
                <DownloadCounter />
                <div
                    style={{ color: "#42c6a3" }}
                    className="font-normal text-lg font-semibold m-4"
                >
                    <Link to="/">10th</Link> &gt; {subject}
                </div>

                {chapterJSONArray.map((chapter, ind) => {
                    //console.log(chapterObj);

                    return (
                        <Link
                            to={`/chapter/${subject}/${encodeURIComponent(
                                chapter.name
                            )}`}
                            key={Number(chapter.ind)}
                        >
                            <div
                                style={{
                                    color: "#555555",
                                    backgroundColor: "#f2f2f2",
                                }}
                                className="flex items-center m-4 py-4 px-3 rounded-lg font-semibold"
                            >
                                <p>
                                    {chapter.ind}. {chapter.name}
                                </p>
                            </div>
                        </Link>
                    );
                })}
            </div>
        </ViewBoxContainer>
    );
});

export default SubjectsPage;
