import { makeObservable, observable, computed, action, autorun } from "mobx";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/remote-config";
import { parseTotalQuestionForSubject } from "../utils/parseTotalQuestionForSubject";
import { firebaseConfig } from "../utils/config";
import {
    DefaultConfigValues,
    DefaultDownloadPageStuff,
    DefaultRCValue,
    DefaultSharePageStuff,
} from "../utils/Consts";

class FSStore {
    firebaseConfig;
    RC;
    RCValue;
    ConfigValues;
    DownloadPage;
    SharePage;
    firebaseAnalytics;

    constructor() {
        this.firebaseConfig = firebaseConfig;
        this.RCValue = DefaultRCValue;
        this.ConfigValues = DefaultConfigValues;
        this.DownloadPage = DefaultDownloadPageStuff;
        this.SharePage = DefaultSharePageStuff;
        this.otp = 0;

        makeObservable(this, {
            firebaseConfig: observable,
            RCValue: observable,
            ConfigValues: observable,
            DownloadPage: observable,
            SharePage: observable,
            otp: observable,

            fireEvent: action,
            fetchRC: action,

            noOfQuestionAllSubject: computed,
        });

        this.fetchRC();
    }

    fireEvent(eventName, eventBody) {
        if (eventName && navigator.cookieEnabled) {
            let date = new Date();
            let options = {
                weekday: "long",
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
            };
            let currentTimeString = date.toLocaleTimeString("en-us", options);

            this.firebaseAnalytics.logEvent(eventName, {
                ...eventBody,
                time: currentTimeString,
            });

            console.log(eventName);
        }
    }

    fetchRC() {
        if (navigator.cookieEnabled) {
            console.log("cookies enabled fetching RC");
            if (firebase.apps.length === 0) {
                firebase.initializeApp(this.firebaseConfig);
            }
            this.firebaseAnalytics = firebase.analytics();

            this.RC = firebase.remoteConfig();
            this.RC.defaultConfig = {
                welcome_message: "Welcome",
            };
            this.RC.settings.minimumFetchIntervalMillis = 2000;
            this.RC.settings.fetchTimeoutMillis = 2000;

            this.RC.fetchAndActivate()
                .then(() => {
                    const val = this.RC.getValue("worksheet_data")._value;
                    const val2 = this.RC.getValue("worksheet_config")._value;
                    const val3 = this.RC.getValue("worksheet_download_page")
                        ._value;
                    const val4 = this.RC.getValue("worksheet_share_page")
                        ._value;

                    this.RCValue = JSON.parse(val);
                    this.ConfigValues = JSON.parse(val2);
                    this.DownloadPage = JSON.parse(val3);
                    this.SharePage = JSON.parse(val4);
                    //console.log("RcValue fetched", this.DownloadPage);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    get noOfQuestionAllSubject() {
        const subjects = ["English", "Maths", "Science", "Social Studies"];

        const returnArray = [];

        subjects.forEach((inst, ind) => {
            returnArray.push({
                subject: inst,
                noOfQuestions: this.RCValue.metaData[ind]
                    .noOfQuestionsInSubject,
            });
        });

        return returnArray;
    }
}

const FSStoreInstance = new FSStore();

export { FSStoreInstance };
