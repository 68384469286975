import { observer } from "mobx-react";

import eyeIcon from "../../assets/images/eye_icon.png";
import getDownloadCount from "../../utils/getDownloadCount";

const DownloadCounter = observer(() => {
    return (
        <div>
            <div
                style={{ backgroundColor: "#ffa768", width: "fit-content" }}
                className=" mx-4 my-4 w-10/12 px-3 py-1 rounded-xl text-white flex "
            >
                <img className=" w-6 h-6 mr-2" src={eyeIcon} />
                <p>{getDownloadCount()} - Worksheets downloaded </p>
            </div>
        </div>
    );
});

export default DownloadCounter;
