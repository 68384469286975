import React from "react";
import { FSStoreInstance } from "../stores/FSstore";
import { useHistory } from "react-router-dom";

const CookiesEnabledCheck = ({ Children }) => {
    const history = useHistory();

    if (!navigator.cookieEnabled) {
        console.log("cookies are disabled");
        history.push("/error/cookies");
    }

    return <Children />;
};

export default CookiesEnabledCheck;
