import React, { useEffect } from "react";
import ViewBoxContainer from "../Layout/ViewBoxContainer";
import { Link, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { FSStoreInstance } from "../stores/FSstore";
import pdfLogo from "../assets/images/pdf.png";
import eyeIcon from "../assets/images/eye_icon.png";
import { userDataInstance } from "../stores/userDataStore";
import { parseTotalQuestionForSubject } from "../utils/parseTotalQuestionForSubject";
import getDownloadCount from "../utils/getDownloadCount";
import DownloadCounter from "../components/Common/DownloadCounter";
import useUpdateChecker from "../customHooks/useUpdateCheck";

const ChaptersPage = observer(() => {
    let { subject, chapter } = useParams();
    const rcValue = useUpdateChecker(FSStoreInstance.RCValue);
    const fireEvent = (eventName, eventBody) =>
        FSStoreInstance.fireEvent(eventName, eventBody);
    const canDownload = useUpdateChecker(userDataInstance.canDownload);
    const links = new Set();
    let linksArray = [];
    let linksJSONArray = [];

    if (rcValue !== undefined) {
        rcValue.files.map((inst) => {
            if (
                inst.subject === subject &&
                inst.chapterName == decodeURIComponent(chapter)
            ) {
                links.add(
                    JSON.stringify({
                        link: inst.link,
                        type: inst.type,
                        chapterInd: inst.chapterInd,
                        setInd: inst.setInd,
                        setMetaData: inst.setMetaData,
                        chapterName: inst.chapterName
                    })
                );
            }
        });
        linksArray = Array.from(links);
        linksArray.map((inst) => {
            linksJSONArray.push(JSON.parse(inst));
        });
        linksJSONArray.sort((a, b) => {
            if (parseInt(a.setInd) > parseInt(b.setInd)) {
                return 1;
            } else return -1;
        });
    }
    useEffect(() => {
        fireEvent("Chapter Page Opened", { subject, chapter });
    }, []);

    return (
        <ViewBoxContainer>
            <div className="pb-24">
                <DownloadCounter />

                <div
                    style={{ color: "#42c6a3" }}
                    className="font-normal text-lg font-semibold m-4"
                >
                    <Link to="/" style={{ color: "#42c6a3" }}>
                        10th
                    </Link>{" "}
                    &gt; <Link to={`/subject/${subject}`}>{subject}</Link> &gt;{" "}
                    {decodeURIComponent(chapter)}
                </div>
                <div className="w-full grid grid-cols-2 px-2">
                    {linksJSONArray.map((inst, ind) => {
                        return (
                            <Link
                                to={
                                    canDownload
                                        ? `/download/${subject}/${inst.chapterName}/${inst.chapterInd}/${inst.setInd}/${inst.type}`
                                        : `/blocker`
                                }
                                key={ind}
                            >
                                <div className="w-full p-2">
                                    <div
                                        style={{
                                            backgroundColor:
                                                inst.type === "Worksheet"
                                                    ? "#f2f2f2"
                                                    : "#d6ffdb",
                                        }}
                                        className="w-full h-full rounded-lg flex flex-col items-center justify-center py-2"
                                    >
                                        <img
                                            className="w-14 h-auto"
                                            src={pdfLogo}
                                        />
                                        <p className="text-lg font-semibold text-gray-500 text-center px-2">
                                            {inst.type === "AnswerKey"
                                                ? "Answer Key"
                                                : "Worksheet"}{" "}
                                            {`${inst.chapterInd}.${inst.setInd}`}{" "}
                                        </p>
                                        <p className="text-sm text-gray-500">
                                            {" "}
                                            {inst.setMetaData.noOfPassage}{" "}
                                            Passages{" "}
                                        </p>
                                        <p className="text-sm text-gray-500">
                                            {" "}
                                            {inst.setMetaData.setData.reduce(
                                                (totalNoOfQuestions, inst) =>
                                                    totalNoOfQuestions +
                                                    Number(inst.noOfQuestions),
                                                0
                                            )}{" "}
                                            questions{" "}
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        );
                    })}
                </div>
            </div>
        </ViewBoxContainer>
    );
});

export default ChaptersPage;
