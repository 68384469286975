import "./assets/css/App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/react";

import Home from "./Pages/Home";
import SubjectsPage from "./Pages/SubjectsPage";
import ChaptersPage from "./Pages/ChaptersPage";
import DownloadPage from "./Pages/DownloadPage";
import BlockerPage from "./Pages/BlockerPage";
import Page404 from "./Pages/Page404";
import Test from "./Pages/Test";
import SentryError from "./Pages/Errors/SentryError";

import { sentryUrl } from "./utils/config";
import CookiesEnabledCheck from "./higherOrderComponents/CookiesEnbledCheck";
import ErrorCookies from "./Pages/Errors/ErrorCookies";

Sentry.init({ dsn: sentryUrl });

function App() {
    return (
        <Router>
            <ErrorBoundary FallbackComponent={SentryError}>
                <Switch>
                    <Route
                        exact
                        path="/"
                        children={<CookiesEnabledCheck Children={Home} />}
                    />

                    <Route
                        exact
                        path="/blocker"
                        children={
                            <CookiesEnabledCheck Children={BlockerPage} />
                        }
                    />
                    <Route
                        exact
                        path="/subject/:subject"
                        children={
                            <CookiesEnabledCheck Children={SubjectsPage} />
                        }
                    />
                    <Route
                        exact
                        path="/chapter/:subject/:chapter"
                        children={
                            <CookiesEnabledCheck Children={ChaptersPage} />
                        }
                    />
                    <Route
                        exact
                        path="/download/:subject/:chapter_name/:chapter/:set/:type"
                        children={
                            <CookiesEnabledCheck Children={DownloadPage} />
                        }
                    />
                    <Route
                        exact
                        path="/error/cookies"
                        children={<ErrorCookies />}
                    />
                    <Route exact path="/test" children={<Test />} />
                    <Route children={<Page404 />} />
                </Switch>
            </ErrorBoundary>
        </Router>
    );
}

export default App;
