// firebase configs
const prodFirebaseConfig = {
    apiKey: "AIzaSyB2lotXteVeQ20UgMgUji4o0pjJgtsPrWs",
    authDomain: "casestudyquestions.firebaseapp.com",
    projectId: "casestudyquestions",
    storageBucket: "casestudyquestions.appspot.com",
    messagingSenderId: "221979060980",
    appId: "1:221979060980:web:982ce66bd674edcae38ca1",
    measurementId: "G-T3T6K9DLG8",
};
const testFirebaseConfig = {
    apiKey: "AIzaSyCG2GnwsnzlwET9W59nB7AVOd1uzKd94_o",
    authDomain: "qa-casestudyquestions.firebaseapp.com",
    projectId: "qa-casestudyquestions",
    storageBucket: "qa-casestudyquestions.appspot.com",
    messagingSenderId: "31600548630",
    appId: "1:31600548630:web:6c6e3bdad08b88a1478e37",
    measurementId: "G-S3ED2ZG0L6",
};

const sentryUrlTest =
    "https://1c392474946a47a286c7fd129b5fe956@o503779.ingest.sentry.io/5670171";
const sentryUrlProd =
    "https://ff0b218deedc403a86936ddea12b2a97@o503779.ingest.sentry.io/5671562";

// server configs
const serverStringProd = "https://homeworkapp.ai/root2/ts";
const serverStringTest = "https://homeworkapp.ai/test/ts";

// set env as "test" for QA
// set env as "Prod" for Production

const env = "Prod";

export const firebaseConfig =
    env === "Prod" ? prodFirebaseConfig : testFirebaseConfig;

export const serverString =
    env === "Prod" ? serverStringProd : serverStringTest;

export const sentryUrl = env === "Prod" ? sentryUrlProd : sentryUrlTest;
