export const startMillis = 1614777758468;

export const DefaultDownloadPageStuff = [
    {
        image:
            "https://case-study-questions.s3.ap-south-1.amazonaws.com/brand-sets/1/logo+%E2%80%93+hw+%E2%80%93+free+-+256.png",
        title: "Want access to 5lac+ questions?",
        body:
            "reate homework / worksheet / quiz from 5lac+ pre-typed questions from the best books",
        cta: "Download the homework app",
        cta_url:
            "https://play.google.com/store/apps/details?id=ai.homework.app.ncert.cbse.maths.science",
    },
];

export const DefaultSharePageStuff = {
    msgData: {
        msg_text:
            "Check out this website. It provides case study question pdfs for 10th CBSE for FREE",
        msg_url: "https://www.casestudyquestions.com/?referrer=",
        msg_img_link:
            "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/brand-sets/1/logo%2B%E2%80%93%2Bhw%2B%E2%80%93%2Bfree%2B-%2B256.png",
    },
    pageData: {
        page_title: "Unlock the remaining case study worksheets",
        page_text: "Share with your fellow teachers",
        page_img_link:
            "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/brand-sets/1/logo%2B%E2%80%93%2Bhw%2B%E2%80%93%2Bfree%2B-%2B256.png",
        cta: "Share now!",
    },
};
export const DefaultConfigValues = {
    paramsList: [
        {
            desc: "timeToStartDownload",
            value: "3",
        },
        {
            desc: "loginBlockerAtCount",
            value: "1",
        },
        {
            desc: "shareBlockerAtCount",
            value: "3",
        },
        {
            desc: "downloadPageBackDelay",
            value: "5",
        },
        {
            desc: "entityList",
            value: [
                "Teacher",
                "Student",
                "Principal",
                "HOD",
                "Vice Principal",
                "Superviser",
                "None",
            ],
        },
    ],
};

export const DefaultRCValue = {
    metaData: [
        {
            subject: "English",
            noOfQuestionsInSubject: 2085,
        },
        {
            subject: "Maths",
            noOfQuestionsInSubject: 1930,
        },
        {
            subject: "Science",
            noOfQuestionsInSubject: 2266,
        },
        {
            subject: "Social Studies",
            noOfQuestionsInSubject: 996,
        },
    ],
    files: [
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "10",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.10%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "11",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.11%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "12",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.12%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "13",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.13%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "14",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.14%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "15",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.15%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "16",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.16%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "17",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.17%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "18",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.18%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.1%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.2%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.3%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.4%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.5%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.6%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "7 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.7%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "8 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.8%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "9 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.9%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "10",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.10%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "11",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.11%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "12",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.12%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "13",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.13%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "14",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.14%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "15",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.15%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "16",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.16%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "17",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.17%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "18",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.18%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.1%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.2%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.3%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.4%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.5%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.6%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "7 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.7%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "8 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.8%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "1",
            chapterName: "Factual Passages",
            setInd: "9 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.9%5D%20-%20%5BFactual%20Passages%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "10",
            chapterName: "Mijbil the Otter",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.1%5D%20-%20%5BMijbil%20the%20Otter%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "10",
            chapterName: "Mijbil the Otter",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.2%5D%20-%20%5BMijbil%20the%20Otter%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "10",
            chapterName: "Mijbil the Otter",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.3%5D%20-%20%5BMijbil%20the%20Otter%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "10",
            chapterName: "Mijbil the Otter",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.4%5D%20-%20%5BMijbil%20the%20Otter%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "10",
            chapterName: "Mijbil the Otter",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.5%5D%20-%20%5BMijbil%20the%20Otter%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "10",
            chapterName: "Mijbil the Otter",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.6%5D%20-%20%5BMijbil%20the%20Otter%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "10",
            chapterName: "Mijbil the Otter",
            setInd: "7 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.7%5D%20-%20%5BMijbil%20the%20Otter%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "10",
            chapterName: "Mijbil the Otter",
            setInd: "8 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.8%5D%20-%20%5BMijbil%20the%20Otter%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "10",
            chapterName: "Mijbil the Otter",
            setInd: "9 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.9%5D%20-%20%5BMijbil%20the%20Otter%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "10",
            chapterName: "Mijbil the Otter",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.1%5D%20-%20%5BMijbil%20the%20Otter%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "10",
            chapterName: "Mijbil the Otter",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.2%5D%20-%20%5BMijbil%20the%20Otter%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "10",
            chapterName: "Mijbil the Otter",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.3%5D%20-%20%5BMijbil%20the%20Otter%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "10",
            chapterName: "Mijbil the Otter",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.4%5D%20-%20%5BMijbil%20the%20Otter%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "10",
            chapterName: "Mijbil the Otter",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.5%5D%20-%20%5BMijbil%20the%20Otter%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "10",
            chapterName: "Mijbil the Otter",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.6%5D%20-%20%5BMijbil%20the%20Otter%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "10",
            chapterName: "Mijbil the Otter",
            setInd: "7 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.7%5D%20-%20%5BMijbil%20the%20Otter%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "10",
            chapterName: "Mijbil the Otter",
            setInd: "8 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.8%5D%20-%20%5BMijbil%20the%20Otter%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "10",
            chapterName: "Mijbil the Otter",
            setInd: "9 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.9%5D%20-%20%5BMijbil%20the%20Otter%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "11",
            chapterName: "Madam Rides the Bus",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.1%5D%20-%20%5BMadam%20Rides%20the%20Bus%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "11",
            chapterName: "Madam Rides the Bus",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.2%5D%20-%20%5BMadam%20Rides%20the%20Bus%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "11",
            chapterName: "Madam Rides the Bus",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.3%5D%20-%20%5BMadam%20Rides%20the%20Bus%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "11",
            chapterName: "Madam Rides the Bus",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.4%5D%20-%20%5BMadam%20Rides%20the%20Bus%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "11",
            chapterName: "Madam Rides the Bus",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.5%5D%20-%20%5BMadam%20Rides%20the%20Bus%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "11",
            chapterName: "Madam Rides the Bus",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.6%5D%20-%20%5BMadam%20Rides%20the%20Bus%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "11",
            chapterName: "Madam Rides the Bus",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.1%5D%20-%20%5BMadam%20Rides%20the%20Bus%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "11",
            chapterName: "Madam Rides the Bus",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.2%5D%20-%20%5BMadam%20Rides%20the%20Bus%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "11",
            chapterName: "Madam Rides the Bus",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.3%5D%20-%20%5BMadam%20Rides%20the%20Bus%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "11",
            chapterName: "Madam Rides the Bus",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.4%5D%20-%20%5BMadam%20Rides%20the%20Bus%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "11",
            chapterName: "Madam Rides the Bus",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.5%5D%20-%20%5BMadam%20Rides%20the%20Bus%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "11",
            chapterName: "Madam Rides the Bus",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.6%5D%20-%20%5BMadam%20Rides%20the%20Bus%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "12",
            chapterName: "The Sermon at Benaras",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter12/casestudyquestion.com%20-%20%5BAnswer%20Key%2012.1%5D%20-%20%5BThe%20Sermon%20at%20Benaras%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "12",
            chapterName: "The Sermon at Benaras",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter12/casestudyquestion.com%20-%20%5BAnswer%20Key%2012.2%5D%20-%20%5BThe%20Sermon%20at%20Benaras%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "12",
            chapterName: "The Sermon at Benaras",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter12/casestudyquestion.com%20-%20%5BAnswer%20Key%2012.3%5D%20-%20%5BThe%20Sermon%20at%20Benaras%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "12",
            chapterName: "The Sermon at Benaras",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter12/casestudyquestion.com%20-%20%5BAnswer%20Key%2012.4%5D%20-%20%5BThe%20Sermon%20at%20Benaras%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "12",
            chapterName: "The Sermon at Benaras",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter12/casestudyquestion.com%20-%20%5BAnswer%20Key%2012.5%5D%20-%20%5BThe%20Sermon%20at%20Benaras%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "12",
            chapterName: "The Sermon at Benaras",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter12/casestudyquestion.com%20-%20%5BWorksheet%2012.1%5D%20-%20%5BThe%20Sermon%20at%20Benaras%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "12",
            chapterName: "The Sermon at Benaras",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter12/casestudyquestion.com%20-%20%5BWorksheet%2012.2%5D%20-%20%5BThe%20Sermon%20at%20Benaras%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "12",
            chapterName: "The Sermon at Benaras",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter12/casestudyquestion.com%20-%20%5BWorksheet%2012.3%5D%20-%20%5BThe%20Sermon%20at%20Benaras%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "12",
            chapterName: "The Sermon at Benaras",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter12/casestudyquestion.com%20-%20%5BWorksheet%2012.4%5D%20-%20%5BThe%20Sermon%20at%20Benaras%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "12",
            chapterName: "The Sermon at Benaras",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter12/casestudyquestion.com%20-%20%5BWorksheet%2012.5%5D%20-%20%5BThe%20Sermon%20at%20Benaras%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "13",
            chapterName: "The Proposal",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter13/casestudyquestion.com%20-%20%5BAnswer%20Key%2013.1%5D%20-%20%5BThe%20Proposal%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "13",
            chapterName: "The Proposal",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter13/casestudyquestion.com%20-%20%5BAnswer%20Key%2013.2%5D%20-%20%5BThe%20Proposal%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "13",
            chapterName: "The Proposal",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter13/casestudyquestion.com%20-%20%5BAnswer%20Key%2013.3%5D%20-%20%5BThe%20Proposal%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "13",
            chapterName: "The Proposal",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter13/casestudyquestion.com%20-%20%5BAnswer%20Key%2013.4%5D%20-%20%5BThe%20Proposal%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "13",
            chapterName: "The Proposal",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter13/casestudyquestion.com%20-%20%5BAnswer%20Key%2013.5%5D%20-%20%5BThe%20Proposal%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "13",
            chapterName: "The Proposal",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter13/casestudyquestion.com%20-%20%5BAnswer%20Key%2013.6%5D%20-%20%5BThe%20Proposal%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "13",
            chapterName: "The Proposal",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter13/casestudyquestion.com%20-%20%5BWorksheet%2013.1%5D%20-%20%5BThe%20Proposal%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "13",
            chapterName: "The Proposal",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter13/casestudyquestion.com%20-%20%5BWorksheet%2013.2%5D%20-%20%5BThe%20Proposal%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "13",
            chapterName: "The Proposal",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter13/casestudyquestion.com%20-%20%5BWorksheet%2013.3%5D%20-%20%5BThe%20Proposal%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "13",
            chapterName: "The Proposal",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter13/casestudyquestion.com%20-%20%5BWorksheet%2013.4%5D%20-%20%5BThe%20Proposal%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "13",
            chapterName: "The Proposal",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter13/casestudyquestion.com%20-%20%5BWorksheet%2013.5%5D%20-%20%5BThe%20Proposal%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "13",
            chapterName: "The Proposal",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter13/casestudyquestion.com%20-%20%5BWorksheet%2013.6%5D%20-%20%5BThe%20Proposal%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "14",
            chapterName: "Dust of Snow",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter14/casestudyquestion.com%20-%20%5BAnswer%20Key%2014.1%5D%20-%20%5BDust%20of%20Snow%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "14",
            chapterName: "Dust of Snow",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter14/casestudyquestion.com%20-%20%5BAnswer%20Key%2014.2%5D%20-%20%5BDust%20of%20Snow%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "14",
            chapterName: "Dust of Snow",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter14/casestudyquestion.com%20-%20%5BAnswer%20Key%2014.3%5D%20-%20%5BDust%20of%20Snow%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "14",
            chapterName: "Dust of Snow",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter14/casestudyquestion.com%20-%20%5BWorksheet%2014.1%5D%20-%20%5BDust%20of%20Snow%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "14",
            chapterName: "Dust of Snow",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter14/casestudyquestion.com%20-%20%5BWorksheet%2014.2%5D%20-%20%5BDust%20of%20Snow%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "14",
            chapterName: "Dust of Snow",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter14/casestudyquestion.com%20-%20%5BWorksheet%2014.3%5D%20-%20%5BDust%20of%20Snow%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "15",
            chapterName: "Fire and Ice",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter15/casestudyquestion.com%20-%20%5BAnswer%20Key%2015.1%5D%20-%20%5BFire%20and%20Ice%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "15",
            chapterName: "Fire and Ice",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter15/casestudyquestion.com%20-%20%5BAnswer%20Key%2015.2%5D%20-%20%5BFire%20and%20Ice%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "15",
            chapterName: "Fire and Ice",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter15/casestudyquestion.com%20-%20%5BAnswer%20Key%2015.3%5D%20-%20%5BFire%20and%20Ice%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "15",
            chapterName: "Fire and Ice",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter15/casestudyquestion.com%20-%20%5BWorksheet%2015.1%5D%20-%20%5BFire%20and%20Ice%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "15",
            chapterName: "Fire and Ice",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter15/casestudyquestion.com%20-%20%5BWorksheet%2015.2%5D%20-%20%5BFire%20and%20Ice%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "15",
            chapterName: "Fire and Ice",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter15/casestudyquestion.com%20-%20%5BWorksheet%2015.3%5D%20-%20%5BFire%20and%20Ice%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "16",
            chapterName: "A Tiger in the Zoo",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter16/casestudyquestion.com%20-%20%5BAnswer%20Key%2016.1%5D%20-%20%5BA%20Tiger%20in%20the%20Zoo%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "16",
            chapterName: "A Tiger in the Zoo",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter16/casestudyquestion.com%20-%20%5BAnswer%20Key%2016.2%5D%20-%20%5BA%20Tiger%20in%20the%20Zoo%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "16",
            chapterName: "A Tiger in the Zoo",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter16/casestudyquestion.com%20-%20%5BAnswer%20Key%2016.3%5D%20-%20%5BA%20Tiger%20in%20the%20Zoo%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "16",
            chapterName: "A Tiger in the Zoo",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter16/casestudyquestion.com%20-%20%5BAnswer%20Key%2016.4%5D%20-%20%5BA%20Tiger%20in%20the%20Zoo%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "16",
            chapterName: "A Tiger in the Zoo",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter16/casestudyquestion.com%20-%20%5BAnswer%20Key%2016.5%5D%20-%20%5BA%20Tiger%20in%20the%20Zoo%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "16",
            chapterName: "A Tiger in the Zoo",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter16/casestudyquestion.com%20-%20%5BAnswer%20Key%2016.6%5D%20-%20%5BA%20Tiger%20in%20the%20Zoo%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "16",
            chapterName: "A Tiger in the Zoo",
            setInd: "7 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter16/casestudyquestion.com%20-%20%5BAnswer%20Key%2016.7%5D%20-%20%5BA%20Tiger%20in%20the%20Zoo%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "16",
            chapterName: "A Tiger in the Zoo",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter16/casestudyquestion.com%20-%20%5BWorksheet%2016.1%5D%20-%20%5BA%20Tiger%20in%20the%20Zoo%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "16",
            chapterName: "A Tiger in the Zoo",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter16/casestudyquestion.com%20-%20%5BWorksheet%2016.2%5D%20-%20%5BA%20Tiger%20in%20the%20Zoo%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "16",
            chapterName: "A Tiger in the Zoo",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter16/casestudyquestion.com%20-%20%5BWorksheet%2016.3%5D%20-%20%5BA%20Tiger%20in%20the%20Zoo%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "16",
            chapterName: "A Tiger in the Zoo",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter16/casestudyquestion.com%20-%20%5BWorksheet%2016.4%5D%20-%20%5BA%20Tiger%20in%20the%20Zoo%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "16",
            chapterName: "A Tiger in the Zoo",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter16/casestudyquestion.com%20-%20%5BWorksheet%2016.5%5D%20-%20%5BA%20Tiger%20in%20the%20Zoo%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "16",
            chapterName: "A Tiger in the Zoo",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter16/casestudyquestion.com%20-%20%5BWorksheet%2016.6%5D%20-%20%5BA%20Tiger%20in%20the%20Zoo%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "16",
            chapterName: "A Tiger in the Zoo",
            setInd: "7 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter16/casestudyquestion.com%20-%20%5BWorksheet%2016.7%5D%20-%20%5BA%20Tiger%20in%20the%20Zoo%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "17",
            chapterName: "How to Tell Wild Animals",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter17/casestudyquestion.com%20-%20%5BAnswer%20Key%2017.1%5D%20-%20%5BHow%20to%20Tell%20Wild%20Animals%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "17",
            chapterName: "How to Tell Wild Animals",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter17/casestudyquestion.com%20-%20%5BAnswer%20Key%2017.2%5D%20-%20%5BHow%20to%20Tell%20Wild%20Animals%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "17",
            chapterName: "How to Tell Wild Animals",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter17/casestudyquestion.com%20-%20%5BAnswer%20Key%2017.3%5D%20-%20%5BHow%20to%20Tell%20Wild%20Animals%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "17",
            chapterName: "How to Tell Wild Animals",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter17/casestudyquestion.com%20-%20%5BAnswer%20Key%2017.4%5D%20-%20%5BHow%20to%20Tell%20Wild%20Animals%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "17",
            chapterName: "How to Tell Wild Animals",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter17/casestudyquestion.com%20-%20%5BAnswer%20Key%2017.5%5D%20-%20%5BHow%20to%20Tell%20Wild%20Animals%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "17",
            chapterName: "How to Tell Wild Animals",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter17/casestudyquestion.com%20-%20%5BAnswer%20Key%2017.6%5D%20-%20%5BHow%20to%20Tell%20Wild%20Animals%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "17",
            chapterName: "How to Tell Wild Animals",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter17/casestudyquestion.com%20-%20%5BWorksheet%2017.1%5D%20-%20%5BHow%20to%20Tell%20Wild%20Animals%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "17",
            chapterName: "How to Tell Wild Animals",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter17/casestudyquestion.com%20-%20%5BWorksheet%2017.2%5D%20-%20%5BHow%20to%20Tell%20Wild%20Animals%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "17",
            chapterName: "How to Tell Wild Animals",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter17/casestudyquestion.com%20-%20%5BWorksheet%2017.3%5D%20-%20%5BHow%20to%20Tell%20Wild%20Animals%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "17",
            chapterName: "How to Tell Wild Animals",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter17/casestudyquestion.com%20-%20%5BWorksheet%2017.4%5D%20-%20%5BHow%20to%20Tell%20Wild%20Animals%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "17",
            chapterName: "How to Tell Wild Animals",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter17/casestudyquestion.com%20-%20%5BWorksheet%2017.5%5D%20-%20%5BHow%20to%20Tell%20Wild%20Animals%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "17",
            chapterName: "How to Tell Wild Animals",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter17/casestudyquestion.com%20-%20%5BWorksheet%2017.6%5D%20-%20%5BHow%20to%20Tell%20Wild%20Animals%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "18",
            chapterName: "The Ball Poem",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter18/casestudyquestion.com%20-%20%5BAnswer%20Key%2018.1%5D%20-%20%5BThe%20Ball%20Poem%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "18",
            chapterName: "The Ball Poem",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter18/casestudyquestion.com%20-%20%5BAnswer%20Key%2018.2%5D%20-%20%5BThe%20Ball%20Poem%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "18",
            chapterName: "The Ball Poem",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter18/casestudyquestion.com%20-%20%5BAnswer%20Key%2018.3%5D%20-%20%5BThe%20Ball%20Poem%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "18",
            chapterName: "The Ball Poem",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter18/casestudyquestion.com%20-%20%5BAnswer%20Key%2018.4%5D%20-%20%5BThe%20Ball%20Poem%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "18",
            chapterName: "The Ball Poem",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter18/casestudyquestion.com%20-%20%5BWorksheet%2018.1%5D%20-%20%5BThe%20Ball%20Poem%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "18",
            chapterName: "The Ball Poem",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter18/casestudyquestion.com%20-%20%5BWorksheet%2018.2%5D%20-%20%5BThe%20Ball%20Poem%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "18",
            chapterName: "The Ball Poem",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter18/casestudyquestion.com%20-%20%5BWorksheet%2018.3%5D%20-%20%5BThe%20Ball%20Poem%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "18",
            chapterName: "The Ball Poem",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter18/casestudyquestion.com%20-%20%5BWorksheet%2018.4%5D%20-%20%5BThe%20Ball%20Poem%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "19",
            chapterName: "Amanda",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter19/casestudyquestion.com%20-%20%5BAnswer%20Key%2019.1%5D%20-%20%5BAmanda%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "19",
            chapterName: "Amanda",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter19/casestudyquestion.com%20-%20%5BAnswer%20Key%2019.2%5D%20-%20%5BAmanda%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "19",
            chapterName: "Amanda",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter19/casestudyquestion.com%20-%20%5BAnswer%20Key%2019.3%5D%20-%20%5BAmanda%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "19",
            chapterName: "Amanda",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter19/casestudyquestion.com%20-%20%5BAnswer%20Key%2019.4%5D%20-%20%5BAmanda%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "19",
            chapterName: "Amanda",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter19/casestudyquestion.com%20-%20%5BWorksheet%2019.1%5D%20-%20%5BAmanda%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "19",
            chapterName: "Amanda",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter19/casestudyquestion.com%20-%20%5BWorksheet%2019.2%5D%20-%20%5BAmanda%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "19",
            chapterName: "Amanda",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter19/casestudyquestion.com%20-%20%5BWorksheet%2019.3%5D%20-%20%5BAmanda%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "19",
            chapterName: "Amanda",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter19/casestudyquestion.com%20-%20%5BWorksheet%2019.4%5D%20-%20%5BAmanda%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "2",
            chapterName: "Section A - Reading ( Type II - Discursive)",
            setInd: "10",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.10%5D%20-%20%5BSection%20A%20%20Reading%20%20Type%20II%20%20Discursive%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "2",
            chapterName: "Section A - Reading ( Type II - Discursive)",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.1%5D%20-%20%5BSection%20A%20%20Reading%20%20Type%20II%20%20Discursive%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "2",
            chapterName: "Section A - Reading ( Type II - Discursive)",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.2%5D%20-%20%5BSection%20A%20%20Reading%20%20Type%20II%20%20Discursive%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "2",
            chapterName: "Section A - Reading ( Type II - Discursive)",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.3%5D%20-%20%5BSection%20A%20%20Reading%20%20Type%20II%20%20Discursive%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "2",
            chapterName: "Section A - Reading ( Type II - Discursive)",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.4%5D%20-%20%5BSection%20A%20%20Reading%20%20Type%20II%20%20Discursive%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "2",
            chapterName: "Section A - Reading ( Type II - Discursive)",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.5%5D%20-%20%5BSection%20A%20%20Reading%20%20Type%20II%20%20Discursive%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "2",
            chapterName: "Section A - Reading ( Type II - Discursive)",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.6%5D%20-%20%5BSection%20A%20%20Reading%20%20Type%20II%20%20Discursive%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "2",
            chapterName: "Section A - Reading ( Type II - Discursive)",
            setInd: "7 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.7%5D%20-%20%5BSection%20A%20%20Reading%20%20Type%20II%20%20Discursive%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "2",
            chapterName: "Section A - Reading ( Type II - Discursive)",
            setInd: "8 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.8%5D%20-%20%5BSection%20A%20%20Reading%20%20Type%20II%20%20Discursive%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "2",
            chapterName: "Section A - Reading ( Type II - Discursive)",
            setInd: "9 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.9%5D%20-%20%5BSection%20A%20%20Reading%20%20Type%20II%20%20Discursive%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "2",
            chapterName: "Section A - Reading ( Type II - Discursive)",
            setInd: "10",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.10%5D%20-%20%5BSection%20A%20%20Reading%20%20Type%20II%20%20Discursive%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "2",
            chapterName: "Section A - Reading ( Type II - Discursive)",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.1%5D%20-%20%5BSection%20A%20%20Reading%20%20Type%20II%20%20Discursive%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "2",
            chapterName: "Section A - Reading ( Type II - Discursive)",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.2%5D%20-%20%5BSection%20A%20%20Reading%20%20Type%20II%20%20Discursive%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "2",
            chapterName: "Section A - Reading ( Type II - Discursive)",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.3%5D%20-%20%5BSection%20A%20%20Reading%20%20Type%20II%20%20Discursive%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "2",
            chapterName: "Section A - Reading ( Type II - Discursive)",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.4%5D%20-%20%5BSection%20A%20%20Reading%20%20Type%20II%20%20Discursive%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "2",
            chapterName: "Section A - Reading ( Type II - Discursive)",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.5%5D%20-%20%5BSection%20A%20%20Reading%20%20Type%20II%20%20Discursive%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "2",
            chapterName: "Section A - Reading ( Type II - Discursive)",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.6%5D%20-%20%5BSection%20A%20%20Reading%20%20Type%20II%20%20Discursive%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "2",
            chapterName: "Section A - Reading ( Type II - Discursive)",
            setInd: "7 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.7%5D%20-%20%5BSection%20A%20%20Reading%20%20Type%20II%20%20Discursive%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "2",
            chapterName: "Section A - Reading ( Type II - Discursive)",
            setInd: "8 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.8%5D%20-%20%5BSection%20A%20%20Reading%20%20Type%20II%20%20Discursive%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "2",
            chapterName: "Section A - Reading ( Type II - Discursive)",
            setInd: "9 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 10,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.9%5D%20-%20%5BSection%20A%20%20Reading%20%20Type%20II%20%20Discursive%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "20",
            chapterName: "Animals",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter20/casestudyquestion.com%20-%20%5BAnswer%20Key%2020.1%5D%20-%20%5BAnimals%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "20",
            chapterName: "Animals",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter20/casestudyquestion.com%20-%20%5BAnswer%20Key%2020.2%5D%20-%20%5BAnimals%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "20",
            chapterName: "Animals",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter20/casestudyquestion.com%20-%20%5BWorksheet%2020.1%5D%20-%20%5BAnimals%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "20",
            chapterName: "Animals",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter20/casestudyquestion.com%20-%20%5BWorksheet%2020.2%5D%20-%20%5BAnimals%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "21",
            chapterName: "The Trees",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter21/casestudyquestion.com%20-%20%5BAnswer%20Key%2021.1%5D%20-%20%5BThe%20Trees%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "21",
            chapterName: "The Trees",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter21/casestudyquestion.com%20-%20%5BAnswer%20Key%2021.2%5D%20-%20%5BThe%20Trees%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "21",
            chapterName: "The Trees",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter21/casestudyquestion.com%20-%20%5BAnswer%20Key%2021.3%5D%20-%20%5BThe%20Trees%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "21",
            chapterName: "The Trees",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter21/casestudyquestion.com%20-%20%5BAnswer%20Key%2021.4%5D%20-%20%5BThe%20Trees%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "21",
            chapterName: "The Trees",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter21/casestudyquestion.com%20-%20%5BWorksheet%2021.1%5D%20-%20%5BThe%20Trees%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "21",
            chapterName: "The Trees",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter21/casestudyquestion.com%20-%20%5BWorksheet%2021.2%5D%20-%20%5BThe%20Trees%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "21",
            chapterName: "The Trees",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter21/casestudyquestion.com%20-%20%5BWorksheet%2021.3%5D%20-%20%5BThe%20Trees%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "21",
            chapterName: "The Trees",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter21/casestudyquestion.com%20-%20%5BWorksheet%2021.4%5D%20-%20%5BThe%20Trees%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "22",
            chapterName: "Fog",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter22/casestudyquestion.com%20-%20%5BAnswer%20Key%2022.1%5D%20-%20%5BFog%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "22",
            chapterName: "Fog",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter22/casestudyquestion.com%20-%20%5BWorksheet%2022.1%5D%20-%20%5BFog%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "23",
            chapterName: "Tale of the Custard Dragon",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter23/casestudyquestion.com%20-%20%5BAnswer%20Key%2023.1%5D%20-%20%5BTale%20of%20the%20Custard%20Dragon%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "23",
            chapterName: "Tale of the Custard Dragon",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter23/casestudyquestion.com%20-%20%5BAnswer%20Key%2023.2%5D%20-%20%5BTale%20of%20the%20Custard%20Dragon%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "23",
            chapterName: "Tale of the Custard Dragon",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter23/casestudyquestion.com%20-%20%5BAnswer%20Key%2023.3%5D%20-%20%5BTale%20of%20the%20Custard%20Dragon%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "23",
            chapterName: "Tale of the Custard Dragon",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter23/casestudyquestion.com%20-%20%5BAnswer%20Key%2023.4%5D%20-%20%5BTale%20of%20the%20Custard%20Dragon%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "23",
            chapterName: "Tale of the Custard Dragon",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter23/casestudyquestion.com%20-%20%5BAnswer%20Key%2023.5%5D%20-%20%5BTale%20of%20the%20Custard%20Dragon%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "23",
            chapterName: "Tale of the Custard Dragon",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter23/casestudyquestion.com%20-%20%5BAnswer%20Key%2023.6%5D%20-%20%5BTale%20of%20the%20Custard%20Dragon%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "23",
            chapterName: "Tale of the Custard Dragon",
            setInd: "7 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter23/casestudyquestion.com%20-%20%5BAnswer%20Key%2023.7%5D%20-%20%5BTale%20of%20the%20Custard%20Dragon%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "23",
            chapterName: "Tale of the Custard Dragon",
            setInd: "8 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter23/casestudyquestion.com%20-%20%5BAnswer%20Key%2023.8%5D%20-%20%5BTale%20of%20the%20Custard%20Dragon%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "23",
            chapterName: "Tale of the Custard Dragon",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter23/casestudyquestion.com%20-%20%5BWorksheet%2023.1%5D%20-%20%5BTale%20of%20the%20Custard%20Dragon%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "23",
            chapterName: "Tale of the Custard Dragon",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter23/casestudyquestion.com%20-%20%5BWorksheet%2023.2%5D%20-%20%5BTale%20of%20the%20Custard%20Dragon%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "23",
            chapterName: "Tale of the Custard Dragon",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter23/casestudyquestion.com%20-%20%5BWorksheet%2023.3%5D%20-%20%5BTale%20of%20the%20Custard%20Dragon%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "23",
            chapterName: "Tale of the Custard Dragon",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter23/casestudyquestion.com%20-%20%5BWorksheet%2023.4%5D%20-%20%5BTale%20of%20the%20Custard%20Dragon%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "23",
            chapterName: "Tale of the Custard Dragon",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter23/casestudyquestion.com%20-%20%5BWorksheet%2023.5%5D%20-%20%5BTale%20of%20the%20Custard%20Dragon%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "23",
            chapterName: "Tale of the Custard Dragon",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter23/casestudyquestion.com%20-%20%5BWorksheet%2023.6%5D%20-%20%5BTale%20of%20the%20Custard%20Dragon%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "23",
            chapterName: "Tale of the Custard Dragon",
            setInd: "7 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter23/casestudyquestion.com%20-%20%5BWorksheet%2023.7%5D%20-%20%5BTale%20of%20the%20Custard%20Dragon%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "23",
            chapterName: "Tale of the Custard Dragon",
            setInd: "8 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter23/casestudyquestion.com%20-%20%5BWorksheet%2023.8%5D%20-%20%5BTale%20of%20the%20Custard%20Dragon%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "24",
            chapterName: "For Anne Gregory",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter24/casestudyquestion.com%20-%20%5BAnswer%20Key%2024.1%5D%20-%20%5BFor%20Anne%20Gregory%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "24",
            chapterName: "For Anne Gregory",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter24/casestudyquestion.com%20-%20%5BAnswer%20Key%2024.2%5D%20-%20%5BFor%20Anne%20Gregory%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "24",
            chapterName: "For Anne Gregory",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter24/casestudyquestion.com%20-%20%5BWorksheet%2024.1%5D%20-%20%5BFor%20Anne%20Gregory%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "24",
            chapterName: "For Anne Gregory",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter24/casestudyquestion.com%20-%20%5BWorksheet%2024.2%5D%20-%20%5BFor%20Anne%20Gregory%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "25",
            chapterName: "A Triumph of Surgery",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter25/casestudyquestion.com%20-%20%5BAnswer%20Key%2025.1%5D%20-%20%5BA%20Triumph%20of%20Surgery%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "25",
            chapterName: "A Triumph of Surgery",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter25/casestudyquestion.com%20-%20%5BAnswer%20Key%2025.2%5D%20-%20%5BA%20Triumph%20of%20Surgery%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "25",
            chapterName: "A Triumph of Surgery",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter25/casestudyquestion.com%20-%20%5BAnswer%20Key%2025.3%5D%20-%20%5BA%20Triumph%20of%20Surgery%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "25",
            chapterName: "A Triumph of Surgery",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter25/casestudyquestion.com%20-%20%5BAnswer%20Key%2025.4%5D%20-%20%5BA%20Triumph%20of%20Surgery%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "25",
            chapterName: "A Triumph of Surgery",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter25/casestudyquestion.com%20-%20%5BAnswer%20Key%2025.5%5D%20-%20%5BA%20Triumph%20of%20Surgery%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "25",
            chapterName: "A Triumph of Surgery",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter25/casestudyquestion.com%20-%20%5BWorksheet%2025.1%5D%20-%20%5BA%20Triumph%20of%20Surgery%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "25",
            chapterName: "A Triumph of Surgery",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter25/casestudyquestion.com%20-%20%5BWorksheet%2025.2%5D%20-%20%5BA%20Triumph%20of%20Surgery%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "25",
            chapterName: "A Triumph of Surgery",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter25/casestudyquestion.com%20-%20%5BWorksheet%2025.3%5D%20-%20%5BA%20Triumph%20of%20Surgery%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "25",
            chapterName: "A Triumph of Surgery",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter25/casestudyquestion.com%20-%20%5BWorksheet%2025.4%5D%20-%20%5BA%20Triumph%20of%20Surgery%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "25",
            chapterName: "A Triumph of Surgery",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter25/casestudyquestion.com%20-%20%5BWorksheet%2025.5%5D%20-%20%5BA%20Triumph%20of%20Surgery%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "26",
            chapterName: "The Thief's Story",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter26/casestudyquestion.com%20-%20%5BAnswer%20Key%2026.1%5D%20-%20%5BThe%20Thiefs%20Story%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "26",
            chapterName: "The Thief's Story",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter26/casestudyquestion.com%20-%20%5BAnswer%20Key%2026.2%5D%20-%20%5BThe%20Thiefs%20Story%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "26",
            chapterName: "The Thief's Story",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter26/casestudyquestion.com%20-%20%5BAnswer%20Key%2026.3%5D%20-%20%5BThe%20Thiefs%20Story%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "26",
            chapterName: "The Thief's Story",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter26/casestudyquestion.com%20-%20%5BAnswer%20Key%2026.4%5D%20-%20%5BThe%20Thiefs%20Story%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "26",
            chapterName: "The Thief's Story",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter26/casestudyquestion.com%20-%20%5BAnswer%20Key%2026.5%5D%20-%20%5BThe%20Thiefs%20Story%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "26",
            chapterName: "The Thief's Story",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter26/casestudyquestion.com%20-%20%5BWorksheet%2026.1%5D%20-%20%5BThe%20Thiefs%20Story%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "26",
            chapterName: "The Thief's Story",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter26/casestudyquestion.com%20-%20%5BWorksheet%2026.2%5D%20-%20%5BThe%20Thiefs%20Story%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "26",
            chapterName: "The Thief's Story",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter26/casestudyquestion.com%20-%20%5BWorksheet%2026.3%5D%20-%20%5BThe%20Thiefs%20Story%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "26",
            chapterName: "The Thief's Story",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter26/casestudyquestion.com%20-%20%5BWorksheet%2026.4%5D%20-%20%5BThe%20Thiefs%20Story%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "26",
            chapterName: "The Thief's Story",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter26/casestudyquestion.com%20-%20%5BWorksheet%2026.5%5D%20-%20%5BThe%20Thiefs%20Story%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "27",
            chapterName: "The Midnight Visitor",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter27/casestudyquestion.com%20-%20%5BAnswer%20Key%2027.1%5D%20-%20%5BThe%20Midnight%20Visitor%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "27",
            chapterName: "The Midnight Visitor",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter27/casestudyquestion.com%20-%20%5BAnswer%20Key%2027.2%5D%20-%20%5BThe%20Midnight%20Visitor%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "27",
            chapterName: "The Midnight Visitor",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter27/casestudyquestion.com%20-%20%5BAnswer%20Key%2027.3%5D%20-%20%5BThe%20Midnight%20Visitor%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "27",
            chapterName: "The Midnight Visitor",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter27/casestudyquestion.com%20-%20%5BAnswer%20Key%2027.4%5D%20-%20%5BThe%20Midnight%20Visitor%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "27",
            chapterName: "The Midnight Visitor",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter27/casestudyquestion.com%20-%20%5BAnswer%20Key%2027.5%5D%20-%20%5BThe%20Midnight%20Visitor%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "27",
            chapterName: "The Midnight Visitor",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter27/casestudyquestion.com%20-%20%5BAnswer%20Key%2027.6%5D%20-%20%5BThe%20Midnight%20Visitor%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "27",
            chapterName: "The Midnight Visitor",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter27/casestudyquestion.com%20-%20%5BWorksheet%2027.1%5D%20-%20%5BThe%20Midnight%20Visitor%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "27",
            chapterName: "The Midnight Visitor",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter27/casestudyquestion.com%20-%20%5BWorksheet%2027.2%5D%20-%20%5BThe%20Midnight%20Visitor%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "27",
            chapterName: "The Midnight Visitor",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter27/casestudyquestion.com%20-%20%5BWorksheet%2027.3%5D%20-%20%5BThe%20Midnight%20Visitor%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "27",
            chapterName: "The Midnight Visitor",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter27/casestudyquestion.com%20-%20%5BWorksheet%2027.4%5D%20-%20%5BThe%20Midnight%20Visitor%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "27",
            chapterName: "The Midnight Visitor",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter27/casestudyquestion.com%20-%20%5BWorksheet%2027.5%5D%20-%20%5BThe%20Midnight%20Visitor%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "27",
            chapterName: "The Midnight Visitor",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter27/casestudyquestion.com%20-%20%5BWorksheet%2027.6%5D%20-%20%5BThe%20Midnight%20Visitor%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "28",
            chapterName: "A Question of Trust",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter28/casestudyquestion.com%20-%20%5BAnswer%20Key%2028.1%5D%20-%20%5BA%20Question%20of%20Trust%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "28",
            chapterName: "A Question of Trust",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter28/casestudyquestion.com%20-%20%5BAnswer%20Key%2028.2%5D%20-%20%5BA%20Question%20of%20Trust%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "28",
            chapterName: "A Question of Trust",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter28/casestudyquestion.com%20-%20%5BAnswer%20Key%2028.3%5D%20-%20%5BA%20Question%20of%20Trust%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "28",
            chapterName: "A Question of Trust",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter28/casestudyquestion.com%20-%20%5BAnswer%20Key%2028.4%5D%20-%20%5BA%20Question%20of%20Trust%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "28",
            chapterName: "A Question of Trust",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter28/casestudyquestion.com%20-%20%5BAnswer%20Key%2028.5%5D%20-%20%5BA%20Question%20of%20Trust%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "28",
            chapterName: "A Question of Trust",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter28/casestudyquestion.com%20-%20%5BWorksheet%2028.1%5D%20-%20%5BA%20Question%20of%20Trust%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "28",
            chapterName: "A Question of Trust",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter28/casestudyquestion.com%20-%20%5BWorksheet%2028.2%5D%20-%20%5BA%20Question%20of%20Trust%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "28",
            chapterName: "A Question of Trust",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter28/casestudyquestion.com%20-%20%5BWorksheet%2028.3%5D%20-%20%5BA%20Question%20of%20Trust%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "28",
            chapterName: "A Question of Trust",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter28/casestudyquestion.com%20-%20%5BWorksheet%2028.4%5D%20-%20%5BA%20Question%20of%20Trust%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "28",
            chapterName: "A Question of Trust",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter28/casestudyquestion.com%20-%20%5BWorksheet%2028.5%5D%20-%20%5BA%20Question%20of%20Trust%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "29",
            chapterName: "Footprints Without Feet",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter29/casestudyquestion.com%20-%20%5BAnswer%20Key%2029.1%5D%20-%20%5BFootprints%20Without%20Feet%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "29",
            chapterName: "Footprints Without Feet",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter29/casestudyquestion.com%20-%20%5BAnswer%20Key%2029.2%5D%20-%20%5BFootprints%20Without%20Feet%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "29",
            chapterName: "Footprints Without Feet",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter29/casestudyquestion.com%20-%20%5BAnswer%20Key%2029.3%5D%20-%20%5BFootprints%20Without%20Feet%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "29",
            chapterName: "Footprints Without Feet",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter29/casestudyquestion.com%20-%20%5BAnswer%20Key%2029.4%5D%20-%20%5BFootprints%20Without%20Feet%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "29",
            chapterName: "Footprints Without Feet",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter29/casestudyquestion.com%20-%20%5BWorksheet%2029.1%5D%20-%20%5BFootprints%20Without%20Feet%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "29",
            chapterName: "Footprints Without Feet",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter29/casestudyquestion.com%20-%20%5BWorksheet%2029.2%5D%20-%20%5BFootprints%20Without%20Feet%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "29",
            chapterName: "Footprints Without Feet",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter29/casestudyquestion.com%20-%20%5BWorksheet%2029.3%5D%20-%20%5BFootprints%20Without%20Feet%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "29",
            chapterName: "Footprints Without Feet",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter29/casestudyquestion.com%20-%20%5BWorksheet%2029.4%5D%20-%20%5BFootprints%20Without%20Feet%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "3",
            chapterName: "A Letter to God",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter3/casestudyquestion.com%20-%20%5BAnswer%20Key%203.1%5D%20-%20%5BA%20Letter%20to%20God%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "3",
            chapterName: "A Letter to God",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter3/casestudyquestion.com%20-%20%5BAnswer%20Key%203.2%5D%20-%20%5BA%20Letter%20to%20God%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "3",
            chapterName: "A Letter to God",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter3/casestudyquestion.com%20-%20%5BAnswer%20Key%203.3%5D%20-%20%5BA%20Letter%20to%20God%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "3",
            chapterName: "A Letter to God",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter3/casestudyquestion.com%20-%20%5BAnswer%20Key%203.4%5D%20-%20%5BA%20Letter%20to%20God%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "3",
            chapterName: "A Letter to God",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter3/casestudyquestion.com%20-%20%5BWorksheet%203.1%5D%20-%20%5BA%20Letter%20to%20God%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "3",
            chapterName: "A Letter to God",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter3/casestudyquestion.com%20-%20%5BWorksheet%203.2%5D%20-%20%5BA%20Letter%20to%20God%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "3",
            chapterName: "A Letter to God",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter3/casestudyquestion.com%20-%20%5BWorksheet%203.3%5D%20-%20%5BA%20Letter%20to%20God%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "3",
            chapterName: "A Letter to God",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter3/casestudyquestion.com%20-%20%5BWorksheet%203.4%5D%20-%20%5BA%20Letter%20to%20God%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "30",
            chapterName: "The Making of a Scientist",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter30/casestudyquestion.com%20-%20%5BAnswer%20Key%2030.1%5D%20-%20%5BThe%20Making%20of%20a%20Scientist%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "30",
            chapterName: "The Making of a Scientist",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter30/casestudyquestion.com%20-%20%5BAnswer%20Key%2030.2%5D%20-%20%5BThe%20Making%20of%20a%20Scientist%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "30",
            chapterName: "The Making of a Scientist",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter30/casestudyquestion.com%20-%20%5BAnswer%20Key%2030.3%5D%20-%20%5BThe%20Making%20of%20a%20Scientist%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "30",
            chapterName: "The Making of a Scientist",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter30/casestudyquestion.com%20-%20%5BAnswer%20Key%2030.4%5D%20-%20%5BThe%20Making%20of%20a%20Scientist%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "30",
            chapterName: "The Making of a Scientist",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter30/casestudyquestion.com%20-%20%5BAnswer%20Key%2030.5%5D%20-%20%5BThe%20Making%20of%20a%20Scientist%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "30",
            chapterName: "The Making of a Scientist",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter30/casestudyquestion.com%20-%20%5BWorksheet%2030.1%5D%20-%20%5BThe%20Making%20of%20a%20Scientist%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "30",
            chapterName: "The Making of a Scientist",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter30/casestudyquestion.com%20-%20%5BWorksheet%2030.2%5D%20-%20%5BThe%20Making%20of%20a%20Scientist%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "30",
            chapterName: "The Making of a Scientist",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter30/casestudyquestion.com%20-%20%5BWorksheet%2030.3%5D%20-%20%5BThe%20Making%20of%20a%20Scientist%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "30",
            chapterName: "The Making of a Scientist",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter30/casestudyquestion.com%20-%20%5BWorksheet%2030.4%5D%20-%20%5BThe%20Making%20of%20a%20Scientist%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "30",
            chapterName: "The Making of a Scientist",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter30/casestudyquestion.com%20-%20%5BWorksheet%2030.5%5D%20-%20%5BThe%20Making%20of%20a%20Scientist%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "31",
            chapterName: "The Necklace",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter31/casestudyquestion.com%20-%20%5BAnswer%20Key%2031.1%5D%20-%20%5BThe%20Necklace%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "31",
            chapterName: "The Necklace",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter31/casestudyquestion.com%20-%20%5BAnswer%20Key%2031.2%5D%20-%20%5BThe%20Necklace%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "31",
            chapterName: "The Necklace",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter31/casestudyquestion.com%20-%20%5BAnswer%20Key%2031.3%5D%20-%20%5BThe%20Necklace%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "31",
            chapterName: "The Necklace",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter31/casestudyquestion.com%20-%20%5BAnswer%20Key%2031.4%5D%20-%20%5BThe%20Necklace%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "31",
            chapterName: "The Necklace",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter31/casestudyquestion.com%20-%20%5BAnswer%20Key%2031.5%5D%20-%20%5BThe%20Necklace%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "31",
            chapterName: "The Necklace",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter31/casestudyquestion.com%20-%20%5BAnswer%20Key%2031.6%5D%20-%20%5BThe%20Necklace%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "31",
            chapterName: "The Necklace",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter31/casestudyquestion.com%20-%20%5BWorksheet%2031.1%5D%20-%20%5BThe%20Necklace%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "31",
            chapterName: "The Necklace",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter31/casestudyquestion.com%20-%20%5BWorksheet%2031.2%5D%20-%20%5BThe%20Necklace%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "31",
            chapterName: "The Necklace",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter31/casestudyquestion.com%20-%20%5BWorksheet%2031.3%5D%20-%20%5BThe%20Necklace%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "31",
            chapterName: "The Necklace",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter31/casestudyquestion.com%20-%20%5BWorksheet%2031.4%5D%20-%20%5BThe%20Necklace%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "31",
            chapterName: "The Necklace",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter31/casestudyquestion.com%20-%20%5BWorksheet%2031.5%5D%20-%20%5BThe%20Necklace%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "31",
            chapterName: "The Necklace",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter31/casestudyquestion.com%20-%20%5BWorksheet%2031.6%5D%20-%20%5BThe%20Necklace%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "32",
            chapterName: "The Hack Driver",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter32/casestudyquestion.com%20-%20%5BAnswer%20Key%2032.1%5D%20-%20%5BThe%20Hack%20Driver%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "32",
            chapterName: "The Hack Driver",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter32/casestudyquestion.com%20-%20%5BAnswer%20Key%2032.2%5D%20-%20%5BThe%20Hack%20Driver%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "32",
            chapterName: "The Hack Driver",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter32/casestudyquestion.com%20-%20%5BAnswer%20Key%2032.3%5D%20-%20%5BThe%20Hack%20Driver%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "32",
            chapterName: "The Hack Driver",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter32/casestudyquestion.com%20-%20%5BAnswer%20Key%2032.4%5D%20-%20%5BThe%20Hack%20Driver%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "32",
            chapterName: "The Hack Driver",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter32/casestudyquestion.com%20-%20%5BAnswer%20Key%2032.5%5D%20-%20%5BThe%20Hack%20Driver%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "32",
            chapterName: "The Hack Driver",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter32/casestudyquestion.com%20-%20%5BAnswer%20Key%2032.6%5D%20-%20%5BThe%20Hack%20Driver%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "32",
            chapterName: "The Hack Driver",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter32/casestudyquestion.com%20-%20%5BWorksheet%2032.1%5D%20-%20%5BThe%20Hack%20Driver%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "32",
            chapterName: "The Hack Driver",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter32/casestudyquestion.com%20-%20%5BWorksheet%2032.2%5D%20-%20%5BThe%20Hack%20Driver%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "32",
            chapterName: "The Hack Driver",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter32/casestudyquestion.com%20-%20%5BWorksheet%2032.3%5D%20-%20%5BThe%20Hack%20Driver%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "32",
            chapterName: "The Hack Driver",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter32/casestudyquestion.com%20-%20%5BWorksheet%2032.4%5D%20-%20%5BThe%20Hack%20Driver%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "32",
            chapterName: "The Hack Driver",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter32/casestudyquestion.com%20-%20%5BWorksheet%2032.5%5D%20-%20%5BThe%20Hack%20Driver%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "32",
            chapterName: "The Hack Driver",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter32/casestudyquestion.com%20-%20%5BWorksheet%2032.6%5D%20-%20%5BThe%20Hack%20Driver%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "33",
            chapterName: "Bholi",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter33/casestudyquestion.com%20-%20%5BAnswer%20Key%2033.1%5D%20-%20%5BBholi%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "33",
            chapterName: "Bholi",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter33/casestudyquestion.com%20-%20%5BAnswer%20Key%2033.2%5D%20-%20%5BBholi%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "33",
            chapterName: "Bholi",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter33/casestudyquestion.com%20-%20%5BAnswer%20Key%2033.3%5D%20-%20%5BBholi%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "33",
            chapterName: "Bholi",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter33/casestudyquestion.com%20-%20%5BAnswer%20Key%2033.4%5D%20-%20%5BBholi%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "33",
            chapterName: "Bholi",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter33/casestudyquestion.com%20-%20%5BAnswer%20Key%2033.5%5D%20-%20%5BBholi%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "33",
            chapterName: "Bholi",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter33/casestudyquestion.com%20-%20%5BAnswer%20Key%2033.6%5D%20-%20%5BBholi%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "33",
            chapterName: "Bholi",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter33/casestudyquestion.com%20-%20%5BWorksheet%2033.1%5D%20-%20%5BBholi%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "33",
            chapterName: "Bholi",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter33/casestudyquestion.com%20-%20%5BWorksheet%2033.2%5D%20-%20%5BBholi%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "33",
            chapterName: "Bholi",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter33/casestudyquestion.com%20-%20%5BWorksheet%2033.3%5D%20-%20%5BBholi%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "33",
            chapterName: "Bholi",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter33/casestudyquestion.com%20-%20%5BWorksheet%2033.4%5D%20-%20%5BBholi%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "33",
            chapterName: "Bholi",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter33/casestudyquestion.com%20-%20%5BWorksheet%2033.5%5D%20-%20%5BBholi%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "33",
            chapterName: "Bholi",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter33/casestudyquestion.com%20-%20%5BWorksheet%2033.6%5D%20-%20%5BBholi%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "34",
            chapterName: "The Book that Saved the Earth",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter34/casestudyquestion.com%20-%20%5BAnswer%20Key%2034.1%5D%20-%20%5BThe%20Book%20that%20Saved%20the%20Earth%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "34",
            chapterName: "The Book that Saved the Earth",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter34/casestudyquestion.com%20-%20%5BAnswer%20Key%2034.2%5D%20-%20%5BThe%20Book%20that%20Saved%20the%20Earth%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "34",
            chapterName: "The Book that Saved the Earth",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter34/casestudyquestion.com%20-%20%5BAnswer%20Key%2034.3%5D%20-%20%5BThe%20Book%20that%20Saved%20the%20Earth%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "34",
            chapterName: "The Book that Saved the Earth",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter34/casestudyquestion.com%20-%20%5BWorksheet%2034.1%5D%20-%20%5BThe%20Book%20that%20Saved%20the%20Earth%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "34",
            chapterName: "The Book that Saved the Earth",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter34/casestudyquestion.com%20-%20%5BWorksheet%2034.2%5D%20-%20%5BThe%20Book%20that%20Saved%20the%20Earth%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "34",
            chapterName: "The Book that Saved the Earth",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter34/casestudyquestion.com%20-%20%5BWorksheet%2034.3%5D%20-%20%5BThe%20Book%20that%20Saved%20the%20Earth%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "4",
            chapterName: "Nelson Mandela: Long Walk to Freedom",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter4/casestudyquestion.com%20-%20%5BAnswer%20Key%204.1%5D%20-%20%5BNelson%20Mandela%20Long%20Walk%20to%20Freedom%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "4",
            chapterName: "Nelson Mandela: Long Walk to Freedom",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter4/casestudyquestion.com%20-%20%5BAnswer%20Key%204.2%5D%20-%20%5BNelson%20Mandela%20Long%20Walk%20to%20Freedom%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "4",
            chapterName: "Nelson Mandela: Long Walk to Freedom",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter4/casestudyquestion.com%20-%20%5BAnswer%20Key%204.3%5D%20-%20%5BNelson%20Mandela%20Long%20Walk%20to%20Freedom%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "4",
            chapterName: "Nelson Mandela: Long Walk to Freedom",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter4/casestudyquestion.com%20-%20%5BAnswer%20Key%204.4%5D%20-%20%5BNelson%20Mandela%20Long%20Walk%20to%20Freedom%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "4",
            chapterName: "Nelson Mandela: Long Walk to Freedom",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter4/casestudyquestion.com%20-%20%5BAnswer%20Key%204.5%5D%20-%20%5BNelson%20Mandela%20Long%20Walk%20to%20Freedom%5D(1).pdf",
        },
        {
            subject: "English",
            chapterInd: "4",
            chapterName: "Nelson Mandela: Long Walk to Freedom",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter4/casestudyquestion.com%20-%20%5BAnswer%20Key%204.5%5D%20-%20%5BNelson%20Mandela%20Long%20Walk%20to%20Freedom%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "4",
            chapterName: "Nelson Mandela: Long Walk to Freedom",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter4/casestudyquestion.com%20-%20%5BWorksheet%204.1%5D%20-%20%5BNelson%20Mandela%20Long%20Walk%20to%20Freedom%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "4",
            chapterName: "Nelson Mandela: Long Walk to Freedom",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter4/casestudyquestion.com%20-%20%5BWorksheet%204.2%5D%20-%20%5BNelson%20Mandela%20Long%20Walk%20to%20Freedom%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "4",
            chapterName: "Nelson Mandela: Long Walk to Freedom",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter4/casestudyquestion.com%20-%20%5BWorksheet%204.3%5D%20-%20%5BNelson%20Mandela%20Long%20Walk%20to%20Freedom%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "4",
            chapterName: "Nelson Mandela: Long Walk to Freedom",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter4/casestudyquestion.com%20-%20%5BWorksheet%204.4%5D%20-%20%5BNelson%20Mandela%20Long%20Walk%20to%20Freedom%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "4",
            chapterName: "Nelson Mandela: Long Walk to Freedom",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter4/casestudyquestion.com%20-%20%5BWorksheet%204.5%5D%20-%20%5BNelson%20Mandela%20Long%20Walk%20to%20Freedom%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "5",
            chapterName: "Two Stories about Flying",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.1%5D%20-%20%5BTwo%20Stories%20about%20Flying%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "5",
            chapterName: "Two Stories about Flying",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.2%5D%20-%20%5BTwo%20Stories%20about%20Flying%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "5",
            chapterName: "Two Stories about Flying",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 0,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.3%5D%20-%20%5BTwo%20Stories%20about%20Flying%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "5",
            chapterName: "Two Stories about Flying",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.4%5D%20-%20%5BTwo%20Stories%20about%20Flying%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "5",
            chapterName: "Two Stories about Flying",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.5%5D%20-%20%5BTwo%20Stories%20about%20Flying%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "5",
            chapterName: "Two Stories about Flying",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.6%5D%20-%20%5BTwo%20Stories%20about%20Flying%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "5",
            chapterName: "Two Stories about Flying",
            setInd: "7 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.7%5D%20-%20%5BTwo%20Stories%20about%20Flying%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "5",
            chapterName: "Two Stories about Flying",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.1%5D%20-%20%5BTwo%20Stories%20about%20Flying%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "5",
            chapterName: "Two Stories about Flying",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.2%5D%20-%20%5BTwo%20Stories%20about%20Flying%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "5",
            chapterName: "Two Stories about Flying",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 0,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.3%5D%20-%20%5BTwo%20Stories%20about%20Flying%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "5",
            chapterName: "Two Stories about Flying",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.4%5D%20-%20%5BTwo%20Stories%20about%20Flying%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "5",
            chapterName: "Two Stories about Flying",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.5%5D%20-%20%5BTwo%20Stories%20about%20Flying%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "5",
            chapterName: "Two Stories about Flying",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.6%5D%20-%20%5BTwo%20Stories%20about%20Flying%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "5",
            chapterName: "Two Stories about Flying",
            setInd: "7 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.7%5D%20-%20%5BTwo%20Stories%20about%20Flying%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "6",
            chapterName: "From the Diary of Anne Frank",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.1%5D%20-%20%5BFrom%20the%20Diary%20of%20Anne%20Frank%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "6",
            chapterName: "From the Diary of Anne Frank",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.2%5D%20-%20%5BFrom%20the%20Diary%20of%20Anne%20Frank%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "6",
            chapterName: "From the Diary of Anne Frank",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.3%5D%20-%20%5BFrom%20the%20Diary%20of%20Anne%20Frank%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "6",
            chapterName: "From the Diary of Anne Frank",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.4%5D%20-%20%5BFrom%20the%20Diary%20of%20Anne%20Frank%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "6",
            chapterName: "From the Diary of Anne Frank",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.5%5D%20-%20%5BFrom%20the%20Diary%20of%20Anne%20Frank%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "6",
            chapterName: "From the Diary of Anne Frank",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.6%5D%20-%20%5BFrom%20the%20Diary%20of%20Anne%20Frank%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "6",
            chapterName: "From the Diary of Anne Frank",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.1%5D%20-%20%5BFrom%20the%20Diary%20of%20Anne%20Frank%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "6",
            chapterName: "From the Diary of Anne Frank",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.2%5D%20-%20%5BFrom%20the%20Diary%20of%20Anne%20Frank%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "6",
            chapterName: "From the Diary of Anne Frank",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.3%5D%20-%20%5BFrom%20the%20Diary%20of%20Anne%20Frank%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "6",
            chapterName: "From the Diary of Anne Frank",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.4%5D%20-%20%5BFrom%20the%20Diary%20of%20Anne%20Frank%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "6",
            chapterName: "From the Diary of Anne Frank",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.5%5D%20-%20%5BFrom%20the%20Diary%20of%20Anne%20Frank%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "6",
            chapterName: "From the Diary of Anne Frank",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.6%5D%20-%20%5BFrom%20the%20Diary%20of%20Anne%20Frank%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "7",
            chapterName: "The Hundred Dresses-I",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter7/casestudyquestion.com%20-%20%5BAnswer%20Key%207.1%5D%20-%20%5BThe%20Hundred%20DressesI%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "7",
            chapterName: "The Hundred Dresses-I",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter7/casestudyquestion.com%20-%20%5BAnswer%20Key%207.2%5D%20-%20%5BThe%20Hundred%20DressesI%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "7",
            chapterName: "The Hundred Dresses-I",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter7/casestudyquestion.com%20-%20%5BAnswer%20Key%207.3%5D%20-%20%5BThe%20Hundred%20DressesI%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "7",
            chapterName: "The Hundred Dresses-I",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter7/casestudyquestion.com%20-%20%5BAnswer%20Key%207.4%5D%20-%20%5BThe%20Hundred%20DressesI%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "7",
            chapterName: "The Hundred Dresses-I",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter7/casestudyquestion.com%20-%20%5BWorksheet%207.1%5D%20-%20%5BThe%20Hundred%20DressesI%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "7",
            chapterName: "The Hundred Dresses-I",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter7/casestudyquestion.com%20-%20%5BWorksheet%207.2%5D%20-%20%5BThe%20Hundred%20DressesI%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "7",
            chapterName: "The Hundred Dresses-I",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter7/casestudyquestion.com%20-%20%5BWorksheet%207.3%5D%20-%20%5BThe%20Hundred%20DressesI%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "7",
            chapterName: "The Hundred Dresses-I",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter7/casestudyquestion.com%20-%20%5BWorksheet%207.4%5D%20-%20%5BThe%20Hundred%20DressesI%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "8",
            chapterName: "The Hundred Dresses-II",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter8/casestudyquestion.com%20-%20%5BAnswer%20Key%208.1%5D%20-%20%5BThe%20Hundred%20DressesII%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "8",
            chapterName: "The Hundred Dresses-II",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter8/casestudyquestion.com%20-%20%5BAnswer%20Key%208.2%5D%20-%20%5BThe%20Hundred%20DressesII%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "8",
            chapterName: "The Hundred Dresses-II",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter8/casestudyquestion.com%20-%20%5BAnswer%20Key%208.3%5D%20-%20%5BThe%20Hundred%20DressesII%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "8",
            chapterName: "The Hundred Dresses-II",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter8/casestudyquestion.com%20-%20%5BAnswer%20Key%208.4%5D%20-%20%5BThe%20Hundred%20DressesII%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "8",
            chapterName: "The Hundred Dresses-II",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter8/casestudyquestion.com%20-%20%5BWorksheet%208.1%5D%20-%20%5BThe%20Hundred%20DressesII%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "8",
            chapterName: "The Hundred Dresses-II",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter8/casestudyquestion.com%20-%20%5BWorksheet%208.2%5D%20-%20%5BThe%20Hundred%20DressesII%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "8",
            chapterName: "The Hundred Dresses-II",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter8/casestudyquestion.com%20-%20%5BWorksheet%208.3%5D%20-%20%5BThe%20Hundred%20DressesII%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "8",
            chapterName: "The Hundred Dresses-II",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter8/casestudyquestion.com%20-%20%5BWorksheet%208.4%5D%20-%20%5BThe%20Hundred%20DressesII%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "9",
            chapterName: "Glimpses of India",
            setInd: "10",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.10%5D%20-%20%5BGlimpses%20of%20India%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "9",
            chapterName: "Glimpses of India",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.1%5D%20-%20%5BGlimpses%20of%20India%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "9",
            chapterName: "Glimpses of India",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.2%5D%20-%20%5BGlimpses%20of%20India%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "9",
            chapterName: "Glimpses of India",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.3%5D%20-%20%5BGlimpses%20of%20India%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "9",
            chapterName: "Glimpses of India",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.4%5D%20-%20%5BGlimpses%20of%20India%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "9",
            chapterName: "Glimpses of India",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.5%5D%20-%20%5BGlimpses%20of%20India%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "9",
            chapterName: "Glimpses of India",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.6%5D%20-%20%5BGlimpses%20of%20India%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "9",
            chapterName: "Glimpses of India",
            setInd: "7 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.7%5D%20-%20%5BGlimpses%20of%20India%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "9",
            chapterName: "Glimpses of India",
            setInd: "8 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.8%5D%20-%20%5BGlimpses%20of%20India%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "9",
            chapterName: "Glimpses of India",
            setInd: "9 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.9%5D%20-%20%5BGlimpses%20of%20India%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "9",
            chapterName: "Glimpses of India",
            setInd: "10",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.10%5D%20-%20%5BGlimpses%20of%20India%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "9",
            chapterName: "Glimpses of India",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.1%5D%20-%20%5BGlimpses%20of%20India%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "9",
            chapterName: "Glimpses of India",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.2%5D%20-%20%5BGlimpses%20of%20India%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "9",
            chapterName: "Glimpses of India",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.3%5D%20-%20%5BGlimpses%20of%20India%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "9",
            chapterName: "Glimpses of India",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.4%5D%20-%20%5BGlimpses%20of%20India%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "9",
            chapterName: "Glimpses of India",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.5%5D%20-%20%5BGlimpses%20of%20India%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "9",
            chapterName: "Glimpses of India",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.6%5D%20-%20%5BGlimpses%20of%20India%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "9",
            chapterName: "Glimpses of India",
            setInd: "7 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.7%5D%20-%20%5BGlimpses%20of%20India%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "9",
            chapterName: "Glimpses of India",
            setInd: "8 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.8%5D%20-%20%5BGlimpses%20of%20India%5D.pdf",
        },
        {
            subject: "English",
            chapterInd: "9",
            chapterName: "Glimpses of India",
            setInd: "9 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/English/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.9%5D%20-%20%5BGlimpses%20of%20India%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "1",
            chapterName: "Real Numbers",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.1%5D%20-%20%5BReal%20Numbers%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "1",
            chapterName: "Real Numbers",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.2%5D%20-%20%5BReal%20Numbers%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "1",
            chapterName: "Real Numbers",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.1%5D%20-%20%5BReal%20Numbers%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "1",
            chapterName: "Real Numbers",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.2%5D%20-%20%5BReal%20Numbers%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "10",
            chapterName: "Circles",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.1%5D%20-%20%5BCircles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "10",
            chapterName: "Circles",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.2%5D%20-%20%5BCircles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "10",
            chapterName: "Circles",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.3%5D%20-%20%5BCircles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "10",
            chapterName: "Circles",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.4%5D%20-%20%5BCircles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "10",
            chapterName: "Circles",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.5%5D%20-%20%5BCircles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "10",
            chapterName: "Circles",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.6%5D%20-%20%5BCircles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "10",
            chapterName: "Circles",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.1%5D%20-%20%5BCircles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "10",
            chapterName: "Circles",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.2%5D%20-%20%5BCircles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "10",
            chapterName: "Circles",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.3%5D%20-%20%5BCircles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "10",
            chapterName: "Circles",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.4%5D%20-%20%5BCircles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "10",
            chapterName: "Circles",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.5%5D%20-%20%5BCircles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "10",
            chapterName: "Circles",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.6%5D%20-%20%5BCircles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "11",
            chapterName: "Constructions",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.1%5D%20-%20%5BConstructions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "11",
            chapterName: "Constructions",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.2%5D%20-%20%5BConstructions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "11",
            chapterName: "Constructions",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.3%5D%20-%20%5BConstructions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "11",
            chapterName: "Constructions",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.4%5D%20-%20%5BConstructions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "11",
            chapterName: "Constructions",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.5%5D%20-%20%5BConstructions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "11",
            chapterName: "Constructions",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.6%5D%20-%20%5BConstructions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "11",
            chapterName: "Constructions",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.1%5D%20-%20%5BConstructions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "11",
            chapterName: "Constructions",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.2%5D%20-%20%5BConstructions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "11",
            chapterName: "Constructions",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.3%5D%20-%20%5BConstructions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "11",
            chapterName: "Constructions",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.4%5D%20-%20%5BConstructions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "11",
            chapterName: "Constructions",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.5%5D%20-%20%5BConstructions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "11",
            chapterName: "Constructions",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.6%5D%20-%20%5BConstructions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "12",
            chapterName: "Areas Related to Circles",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter12/casestudyquestion.com%20-%20%5BAnswer%20Key%2012.1%5D%20-%20%5BAreas%20Related%20to%20Circles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "12",
            chapterName: "Areas Related to Circles",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter12/casestudyquestion.com%20-%20%5BAnswer%20Key%2012.2%5D%20-%20%5BAreas%20Related%20to%20Circles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "12",
            chapterName: "Areas Related to Circles",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter12/casestudyquestion.com%20-%20%5BAnswer%20Key%2012.3%5D%20-%20%5BAreas%20Related%20to%20Circles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "12",
            chapterName: "Areas Related to Circles",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter12/casestudyquestion.com%20-%20%5BAnswer%20Key%2012.4%5D%20-%20%5BAreas%20Related%20to%20Circles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "12",
            chapterName: "Areas Related to Circles",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter12/casestudyquestion.com%20-%20%5BAnswer%20Key%2012.5%5D%20-%20%5BAreas%20Related%20to%20Circles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "12",
            chapterName: "Areas Related to Circles",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 4,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter12/casestudyquestion.com%20-%20%5BAnswer%20Key%2012.6%5D%20-%20%5BAreas%20Related%20to%20Circles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "12",
            chapterName: "Areas Related to Circles",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter12/casestudyquestion.com%20-%20%5BWorksheet%2012.1%5D%20-%20%5BAreas%20Related%20to%20Circles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "12",
            chapterName: "Areas Related to Circles",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter12/casestudyquestion.com%20-%20%5BWorksheet%2012.2%5D%20-%20%5BAreas%20Related%20to%20Circles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "12",
            chapterName: "Areas Related to Circles",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter12/casestudyquestion.com%20-%20%5BWorksheet%2012.3%5D%20-%20%5BAreas%20Related%20to%20Circles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "12",
            chapterName: "Areas Related to Circles",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter12/casestudyquestion.com%20-%20%5BWorksheet%2012.4%5D%20-%20%5BAreas%20Related%20to%20Circles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "12",
            chapterName: "Areas Related to Circles",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter12/casestudyquestion.com%20-%20%5BWorksheet%2012.5%5D%20-%20%5BAreas%20Related%20to%20Circles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "12",
            chapterName: "Areas Related to Circles",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 10,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 4,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter12/casestudyquestion.com%20-%20%5BWorksheet%2012.6%5D%20-%20%5BAreas%20Related%20to%20Circles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "13",
            chapterName: "Surface, Areas and Volumes",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter13/casestudyquestion.com%20-%20%5BAnswer%20Key%2013.1%5D%20-%20%5BSURFACE%20AREA%20AND%20VOLUME%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "13",
            chapterName: "Surface, Areas and Volumes",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter13/casestudyquestion.com%20-%20%5BAnswer%20Key%2013.2%5D%20-%20%5BSURFACE%20AREA%20AND%20VOLUME%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "13",
            chapterName: "Surface, Areas and Volumes",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter13/casestudyquestion.com%20-%20%5BAnswer%20Key%2013.3%5D%20-%20%5BSURFACE%20AREA%20AND%20VOLUME%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "13",
            chapterName: "Surface, Areas and Volumes",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter13/casestudyquestion.com%20-%20%5BAnswer%20Key%2013.5%5D%20-%20%5BSURFACE%20AREA%20AND%20VOLUME%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "13",
            chapterName: "Surface, Areas and Volumes",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter13/casestudyquestion.com%20-%20%5BWorksheet%2013.1%5D%20-%20%5BSURFACE%20AREA%20AND%20VOLUME%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "13",
            chapterName: "Surface, Areas and Volumes",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter13/casestudyquestion.com%20-%20%5BWorksheet%2013.2%5D%20-%20%5BSURFACE%20AREA%20AND%20VOLUME%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "13",
            chapterName: "Surface, Areas and Volumes",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter13/casestudyquestion.com%20-%20%5BWorksheet%2013.3%5D%20-%20%5BSURFACE%20AREA%20AND%20VOLUME%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "13",
            chapterName: "Surface, Areas and Volumes",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter13/casestudyquestion.com%20-%20%5BWorksheet%2013.5%5D%20-%20%5BSURFACE%20AREA%20AND%20VOLUME%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "14",
            chapterName: "Statistics",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter14/casestudyquestion.com%20-%20%5BAnswer%20Key%2014.1%5D%20-%20%5BStatistics%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "14",
            chapterName: "Statistics",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter14/casestudyquestion.com%20-%20%5BAnswer%20Key%2014.2%5D%20-%20%5BStatistics%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "14",
            chapterName: "Statistics",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter14/casestudyquestion.com%20-%20%5BAnswer%20Key%2014.3%5D%20-%20%5BStatistics%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "14",
            chapterName: "Statistics",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter14/casestudyquestion.com%20-%20%5BAnswer%20Key%2014.4%5D%20-%20%5BStatistics%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "14",
            chapterName: "Statistics",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter14/casestudyquestion.com%20-%20%5BAnswer%20Key%2014.5%5D%20-%20%5BStatistics%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "14",
            chapterName: "Statistics",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter14/casestudyquestion.com%20-%20%5BAnswer%20Key%2014.6%5D%20-%20%5BStatistics%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "14",
            chapterName: "Statistics",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter14/casestudyquestion.com%20-%20%5BWorksheet%2014.1%5D%20-%20%5BStatistics%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "14",
            chapterName: "Statistics",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter14/casestudyquestion.com%20-%20%5BWorksheet%2014.2%5D%20-%20%5BStatistics%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "14",
            chapterName: "Statistics",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter14/casestudyquestion.com%20-%20%5BWorksheet%2014.3%5D%20-%20%5BStatistics%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "14",
            chapterName: "Statistics",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter14/casestudyquestion.com%20-%20%5BWorksheet%2014.4%5D%20-%20%5BStatistics%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "14",
            chapterName: "Statistics",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter14/casestudyquestion.com%20-%20%5BWorksheet%2014.5%5D%20-%20%5BStatistics%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "14",
            chapterName: "Statistics",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter14/casestudyquestion.com%20-%20%5BWorksheet%2014.6%5D%20-%20%5BStatistics%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "15",
            chapterName: "Probability",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter15/casestudyquestion.com%20-%20%5BAnswer%20Key%2015.2%5D%20-%20%5BProbability%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "15",
            chapterName: "Probability",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter15/casestudyquestion.com%20-%20%5BAnswer%20Key%2015.3%5D%20-%20%5BProbability%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "15",
            chapterName: "Probability",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter15/casestudyquestion.com%20-%20%5BAnswer%20Key%2015.4%5D%20-%20%5BProbability%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "15",
            chapterName: "Probability",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter15/casestudyquestion.com%20-%20%5BAnswer%20Key%2015.5%5D%20-%20%5BProbability%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "15",
            chapterName: "Probability",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter15/casestudyquestion.com%20-%20%5BAnswer%20Key%2015.6%5D%20-%20%5BProbability%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "15",
            chapterName: "Probability",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter15/casestudyquestion.com%20-%20%5BWorksheet%2015.2%5D%20-%20%5BProbability%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "15",
            chapterName: "Probability",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter15/casestudyquestion.com%20-%20%5BWorksheet%2015.3%5D%20-%20%5BProbability%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "15",
            chapterName: "Probability",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter15/casestudyquestion.com%20-%20%5BWorksheet%2015.4%5D%20-%20%5BProbability%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "15",
            chapterName: "Probability",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter15/casestudyquestion.com%20-%20%5BWorksheet%2015.5%5D%20-%20%5BProbability%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "15",
            chapterName: "Probability",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter15/casestudyquestion.com%20-%20%5BWorksheet%2015.6%5D%20-%20%5BProbability%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "2",
            chapterName: "Polynomials",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.1%5D%20-%20%5BPolynomials%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "2",
            chapterName: "Polynomials",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.1%5D%20-%20%5BPolynomials%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "3",
            chapterName: "Linear Equation In Two Variables",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter3/casestudyquestion.com%20-%20%5BAnswer%20Key%203.1%5D%20-%20%5BLinear%20Equation%20In%20Two%20Variables%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "3",
            chapterName: "Linear Equation In Two Variables",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter3/casestudyquestion.com%20-%20%5BAnswer%20Key%203.2%5D%20-%20%5BLinear%20Equation%20In%20Two%20Variables%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "3",
            chapterName: "Linear Equation In Two Variables",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter3/casestudyquestion.com%20-%20%5BAnswer%20Key%203.3%5D%20-%20%5BLinear%20Equation%20In%20Two%20Variables%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "3",
            chapterName: "Linear Equation In Two Variables",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter3/casestudyquestion.com%20-%20%5BAnswer%20Key%203.4%5D%20-%20%5BLinear%20Equation%20In%20Two%20Variables%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "3",
            chapterName: "Linear Equation In Two Variables",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter3/casestudyquestion.com%20-%20%5BAnswer%20Key%203.5%5D%20-%20%5BLinear%20Equation%20In%20Two%20Variables%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "3",
            chapterName: "Linear Equation In Two Variables",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter3/casestudyquestion.com%20-%20%5BAnswer%20Key%203.6%5D%20-%20%5BLinear%20Equation%20In%20Two%20Variables%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "3",
            chapterName: "Linear Equation In Two Variables",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter3/casestudyquestion.com%20-%20%5BWorksheet%203.1%5D%20-%20%5BLinear%20Equation%20In%20Two%20Variables%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "3",
            chapterName: "Linear Equation In Two Variables",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter3/casestudyquestion.com%20-%20%5BWorksheet%203.2%5D%20-%20%5BLinear%20Equation%20In%20Two%20Variables%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "3",
            chapterName: "Linear Equation In Two Variables",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter3/casestudyquestion.com%20-%20%5BWorksheet%203.3%5D%20-%20%5BLinear%20Equation%20In%20Two%20Variables%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "3",
            chapterName: "Linear Equation In Two Variables",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter3/casestudyquestion.com%20-%20%5BWorksheet%203.4%5D%20-%20%5BLinear%20Equation%20In%20Two%20Variables%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "3",
            chapterName: "Linear Equation In Two Variables",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter3/casestudyquestion.com%20-%20%5BWorksheet%203.5%5D%20-%20%5BLinear%20Equation%20In%20Two%20Variables%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "3",
            chapterName: "Linear Equation In Two Variables",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter3/casestudyquestion.com%20-%20%5BWorksheet%203.6%5D%20-%20%5BLinear%20Equation%20In%20Two%20Variables%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "4",
            chapterName: "Quadratic Equations",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter4/casestudyquestion.com%20-%20%5BAnswer%20Key%204.1%5D%20-%20%5BQuadratic%20Equations%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "4",
            chapterName: "Quadratic Equations",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter4/casestudyquestion.com%20-%20%5BAnswer%20Key%204.2%5D%20-%20%5BQuadratic%20Equations%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "4",
            chapterName: "Quadratic Equations",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter4/casestudyquestion.com%20-%20%5BAnswer%20Key%204.3%5D%20-%20%5BQuadratic%20Equations%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "4",
            chapterName: "Quadratic Equations",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter4/casestudyquestion.com%20-%20%5BAnswer%20Key%204.4%5D%20-%20%5BQuadratic%20Equations%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "4",
            chapterName: "Quadratic Equations",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter4/casestudyquestion.com%20-%20%5BAnswer%20Key%204.5%5D%20-%20%5BQuadratic%20Equations%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "4",
            chapterName: "Quadratic Equations",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter4/casestudyquestion.com%20-%20%5BAnswer%20Key%204.6%5D%20-%20%5BQuadratic%20Equations%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "4",
            chapterName: "Quadratic Equations",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter4/casestudyquestion.com%20-%20%5BWorksheet%204.1%5D%20-%20%5BQuadratic%20Equations%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "4",
            chapterName: "Quadratic Equations",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter4/casestudyquestion.com%20-%20%5BWorksheet%204.2%5D%20-%20%5BQuadratic%20Equations%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "4",
            chapterName: "Quadratic Equations",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter4/casestudyquestion.com%20-%20%5BWorksheet%204.3%5D%20-%20%5BQuadratic%20Equations%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "4",
            chapterName: "Quadratic Equations",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter4/casestudyquestion.com%20-%20%5BWorksheet%204.4%5D%20-%20%5BQuadratic%20Equations%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "4",
            chapterName: "Quadratic Equations",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter4/casestudyquestion.com%20-%20%5BWorksheet%204.5%5D%20-%20%5BQuadratic%20Equations%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "4",
            chapterName: "Quadratic Equations",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter4/casestudyquestion.com%20-%20%5BWorksheet%204.6%5D%20-%20%5BQuadratic%20Equations%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "5",
            chapterName: "Arithmetic Progressions",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.1%5D%20-%20%5BArithmetic%20Progressions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "5",
            chapterName: "Arithmetic Progressions",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.2%5D%20-%20%5BArithmetic%20Progressions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "5",
            chapterName: "Arithmetic Progressions",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.3%5D%20-%20%5BArithmetic%20Progressions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "5",
            chapterName: "Arithmetic Progressions",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.4%5D%20-%20%5BArithmetic%20Progressions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "5",
            chapterName: "Arithmetic Progressions",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.5%5D%20-%20%5BArithmetic%20Progressions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "5",
            chapterName: "Arithmetic Progressions",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.6%5D%20-%20%5BArithmetic%20Progressions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "5",
            chapterName: "Arithmetic Progressions",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.1%5D%20-%20%5BArithmetic%20Progressions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "5",
            chapterName: "Arithmetic Progressions",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.2%5D%20-%20%5BArithmetic%20Progressions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "5",
            chapterName: "Arithmetic Progressions",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.3%5D%20-%20%5BArithmetic%20Progressions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "5",
            chapterName: "Arithmetic Progressions",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.4%5D%20-%20%5BArithmetic%20Progressions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "5",
            chapterName: "Arithmetic Progressions",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.5%5D%20-%20%5BArithmetic%20Progressions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "5",
            chapterName: "Arithmetic Progressions",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.6%5D%20-%20%5BArithmetic%20Progressions%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "6",
            chapterName: "Triangles",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.1%5D%20-%20%5BTriangles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "6",
            chapterName: "Triangles",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.2%5D%20-%20%5BTriangles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "6",
            chapterName: "Triangles",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.3%5D%20-%20%5BTriangles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "6",
            chapterName: "Triangles",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.4%5D%20-%20%5BTriangles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "6",
            chapterName: "Triangles",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.5%5D%20-%20%5BTriangles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "6",
            chapterName: "Triangles",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 6,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.6%5D%20-%20%5BTriangles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "6",
            chapterName: "Triangles",
            setInd: "7 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 4,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.7%5D%20-%20%5BTriangles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "6",
            chapterName: "Triangles",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.1%5D%20-%20%5BTriangles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "6",
            chapterName: "Triangles",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.2%5D%20-%20%5BTriangles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "6",
            chapterName: "Triangles",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.3%5D%20-%20%5BTriangles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "6",
            chapterName: "Triangles",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.4%5D%20-%20%5BTriangles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "6",
            chapterName: "Triangles",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.5%5D%20-%20%5BTriangles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "6",
            chapterName: "Triangles",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 6,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.6%5D%20-%20%5BTriangles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "6",
            chapterName: "Triangles",
            setInd: "7 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 4,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.7%5D%20-%20%5BTriangles%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "7",
            chapterName: "Chapter 7 Coordinate Geometry",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter7/casestudyquestion.com%20-%20%5BAnswer%20Key%207.1%5D%20-%20%5BChapter%20%20Coordinate%20Geometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "7",
            chapterName: "Chapter 7 Coordinate Geometry",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter7/casestudyquestion.com%20-%20%5BAnswer%20Key%207.2%5D%20-%20%5BChapter%20%20Coordinate%20Geometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "7",
            chapterName: "Chapter 7 Coordinate Geometry",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter7/casestudyquestion.com%20-%20%5BAnswer%20Key%207.3%5D%20-%20%5BChapter%20%20Coordinate%20Geometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "7",
            chapterName: "Chapter 7 Coordinate Geometry",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter7/casestudyquestion.com%20-%20%5BAnswer%20Key%207.4%5D%20-%20%5BChapter%20%20Coordinate%20Geometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "7",
            chapterName: "Chapter 7 Coordinate Geometry",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter7/casestudyquestion.com%20-%20%5BAnswer%20Key%207.5%5D%20-%20%5BChapter%20%20Coordinate%20Geometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "7",
            chapterName: "Chapter 7 Coordinate Geometry",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter7/casestudyquestion.com%20-%20%5BAnswer%20Key%207.6%5D%20-%20%5BChapter%20%20Coordinate%20Geometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "7",
            chapterName: "Chapter 7 Coordinate Geometry",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter7/casestudyquestion.com%20-%20%5BWorksheet%207.1%5D%20-%20%5BChapter%20%20Coordinate%20Geometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "7",
            chapterName: "Chapter 7 Coordinate Geometry",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter7/casestudyquestion.com%20-%20%5BWorksheet%207.2%5D%20-%20%5BChapter%20%20Coordinate%20Geometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "7",
            chapterName: "Chapter 7 Coordinate Geometry",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter7/casestudyquestion.com%20-%20%5BWorksheet%207.3%5D%20-%20%5BChapter%20%20Coordinate%20Geometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "7",
            chapterName: "Chapter 7 Coordinate Geometry",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter7/casestudyquestion.com%20-%20%5BWorksheet%207.4%5D%20-%20%5BChapter%20%20Coordinate%20Geometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "7",
            chapterName: "Chapter 7 Coordinate Geometry",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter7/casestudyquestion.com%20-%20%5BWorksheet%207.5%5D%20-%20%5BChapter%20%20Coordinate%20Geometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "7",
            chapterName: "Chapter 7 Coordinate Geometry",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter7/casestudyquestion.com%20-%20%5BWorksheet%207.6%5D%20-%20%5BChapter%20%20Coordinate%20Geometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "8",
            chapterName: "Chapter 8 Introduction to Trigonometry",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter8/casestudyquestion.com%20-%20%5BAnswer%20Key%208.1%5D%20-%20%5BChapter%20%20Introduction%20to%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "8",
            chapterName: "Chapter 8 Introduction to Trigonometry",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter8/casestudyquestion.com%20-%20%5BAnswer%20Key%208.2%5D%20-%20%5BChapter%20%20Introduction%20to%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "8",
            chapterName: "Chapter 8 Introduction to Trigonometry",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter8/casestudyquestion.com%20-%20%5BAnswer%20Key%208.3%5D%20-%20%5BChapter%20%20Introduction%20to%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "8",
            chapterName: "Chapter 8 Introduction to Trigonometry",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter8/casestudyquestion.com%20-%20%5BAnswer%20Key%208.4%5D%20-%20%5BChapter%20%20Introduction%20to%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "8",
            chapterName: "Chapter 8 Introduction to Trigonometry",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter8/casestudyquestion.com%20-%20%5BAnswer%20Key%208.5%5D%20-%20%5BChapter%20%20Introduction%20to%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "8",
            chapterName: "Chapter 8 Introduction to Trigonometry",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter8/casestudyquestion.com%20-%20%5BAnswer%20Key%208.6%5D%20-%20%5BChapter%20%20Introduction%20to%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "8",
            chapterName: "Chapter 8 Introduction to Trigonometry",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter8/casestudyquestion.com%20-%20%5BWorksheet%208.1%5D%20-%20%5BChapter%20%20Introduction%20to%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "8",
            chapterName: "Chapter 8 Introduction to Trigonometry",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter8/casestudyquestion.com%20-%20%5BWorksheet%208.2%5D%20-%20%5BChapter%20%20Introduction%20to%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "8",
            chapterName: "Chapter 8 Introduction to Trigonometry",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter8/casestudyquestion.com%20-%20%5BWorksheet%208.3%5D%20-%20%5BChapter%20%20Introduction%20to%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "8",
            chapterName: "Chapter 8 Introduction to Trigonometry",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter8/casestudyquestion.com%20-%20%5BWorksheet%208.4%5D%20-%20%5BChapter%20%20Introduction%20to%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "8",
            chapterName: "Chapter 8 Introduction to Trigonometry",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter8/casestudyquestion.com%20-%20%5BWorksheet%208.5%5D%20-%20%5BChapter%20%20Introduction%20to%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "8",
            chapterName: "Chapter 8 Introduction to Trigonometry",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter8/casestudyquestion.com%20-%20%5BWorksheet%208.6%5D%20-%20%5BChapter%20%20Introduction%20to%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "9",
            chapterName: "Applications of Trigonometry",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.1%5D%20-%20%5BApplications%20of%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "9",
            chapterName: "Applications of Trigonometry",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.2%5D%20-%20%5BApplications%20of%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "9",
            chapterName: "Applications of Trigonometry",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.3%5D%20-%20%5BApplications%20of%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "9",
            chapterName: "Applications of Trigonometry",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 0,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.4%5D%20-%20%5BApplications%20of%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "9",
            chapterName: "Applications of Trigonometry",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.5%5D%20-%20%5BApplications%20of%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "9",
            chapterName: "Applications of Trigonometry",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.6%5D%20-%20%5BApplications%20of%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "9",
            chapterName: "Applications of Trigonometry",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.1%5D%20-%20%5BApplications%20of%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "9",
            chapterName: "Applications of Trigonometry",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.2%5D%20-%20%5BApplications%20of%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "9",
            chapterName: "Applications of Trigonometry",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.3%5D%20-%20%5BApplications%20of%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "9",
            chapterName: "Applications of Trigonometry",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 0,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.4%5D%20-%20%5BApplications%20of%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "9",
            chapterName: "Applications of Trigonometry",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.5%5D%20-%20%5BApplications%20of%20Trigonometry%5D.pdf",
        },
        {
            subject: "Maths",
            chapterInd: "9",
            chapterName: "Applications of Trigonometry",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Maths/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.6%5D%20-%20%5BApplications%20of%20Trigonometry%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "1",
            chapterName: "Chemical Reactions and Equations",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.1%5D%20-%20%5BChemical%20Reactions%20and%20Equations%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "1",
            chapterName: "Chemical Reactions and Equations",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.2%5D%20-%20%5BChemical%20Reactions%20and%20Equations%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "1",
            chapterName: "Chemical Reactions and Equations",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.3%5D%20-%20%5BChemical%20Reactions%20and%20Equations%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "1",
            chapterName: "Chemical Reactions and Equations",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.4%5D%20-%20%5BChemical%20Reactions%20and%20Equations%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "1",
            chapterName: "Chemical Reactions and Equations",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.5%5D%20-%20%5BChemical%20Reactions%20and%20Equations%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "1",
            chapterName: "Chemical Reactions and Equations",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.6%5D%20-%20%5BChemical%20Reactions%20and%20Equations%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "1",
            chapterName: "Chemical Reactions and Equations",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.1%5D%20-%20%5BChemical%20Reactions%20and%20Equations%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "1",
            chapterName: "Chemical Reactions and Equations",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.2%5D%20-%20%5BChemical%20Reactions%20and%20Equations%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "1",
            chapterName: "Chemical Reactions and Equations",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.3%5D%20-%20%5BChemical%20Reactions%20and%20Equations%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "1",
            chapterName: "Chemical Reactions and Equations",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.4%5D%20-%20%5BChemical%20Reactions%20and%20Equations%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "1",
            chapterName: "Chemical Reactions and Equations",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.5%5D%20-%20%5BChemical%20Reactions%20and%20Equations%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "1",
            chapterName: "Chemical Reactions and Equations",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.6%5D%20-%20%5BChemical%20Reactions%20and%20Equations%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "10",
            chapterName: "Light - Reflection and Refraction",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.1%5D%20-%20%5BLight%20%20Reflection%20and%20Refraction%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "10",
            chapterName: "Light - Reflection and Refraction",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.2%5D%20-%20%5BLight%20%20Reflection%20and%20Refraction%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "10",
            chapterName: "Light - Reflection and Refraction",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.3%5D%20-%20%5BLight%20%20Reflection%20and%20Refraction%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "10",
            chapterName: "Light - Reflection and Refraction",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.4%5D%20-%20%5BLight%20%20Reflection%20and%20Refraction%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "10",
            chapterName: "Light - Reflection and Refraction",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.5%5D%20-%20%5BLight%20%20Reflection%20and%20Refraction%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "10",
            chapterName: "Light - Reflection and Refraction",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.6%5D%20-%20%5BLight%20%20Reflection%20and%20Refraction%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "10",
            chapterName: "Light - Reflection and Refraction",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.1%5D%20-%20%5BLight%20%20Reflection%20and%20Refraction%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "10",
            chapterName: "Light - Reflection and Refraction",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.2%5D%20-%20%5BLight%20%20Reflection%20and%20Refraction%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "10",
            chapterName: "Light - Reflection and Refraction",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.3%5D%20-%20%5BLight%20%20Reflection%20and%20Refraction%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "10",
            chapterName: "Light - Reflection and Refraction",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.4%5D%20-%20%5BLight%20%20Reflection%20and%20Refraction%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "10",
            chapterName: "Light - Reflection and Refraction",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.5%5D%20-%20%5BLight%20%20Reflection%20and%20Refraction%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "10",
            chapterName: "Light - Reflection and Refraction",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.6%5D%20-%20%5BLight%20%20Reflection%20and%20Refraction%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "11",
            chapterName: "Human Eye and Colourful World",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.1%5D%20-%20%5BHuman%20Eye%20and%20Colourful%20World%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "11",
            chapterName: "Human Eye and Colourful World",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.2%5D%20-%20%5BHuman%20Eye%20and%20Colourful%20World%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "11",
            chapterName: "Human Eye and Colourful World",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.3%5D%20-%20%5BHuman%20Eye%20and%20Colourful%20World%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "11",
            chapterName: "Human Eye and Colourful World",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.4%5D%20-%20%5BHuman%20Eye%20and%20Colourful%20World%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "11",
            chapterName: "Human Eye and Colourful World",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.5%5D%20-%20%5BHuman%20Eye%20and%20Colourful%20World%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "11",
            chapterName: "Human Eye and Colourful World",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.6%5D%20-%20%5BHuman%20Eye%20and%20Colourful%20World%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "11",
            chapterName: "Human Eye and Colourful World",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.1%5D%20-%20%5BHuman%20Eye%20and%20Colourful%20World%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "11",
            chapterName: "Human Eye and Colourful World",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.2%5D%20-%20%5BHuman%20Eye%20and%20Colourful%20World%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "11",
            chapterName: "Human Eye and Colourful World",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.3%5D%20-%20%5BHuman%20Eye%20and%20Colourful%20World%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "11",
            chapterName: "Human Eye and Colourful World",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.4%5D%20-%20%5BHuman%20Eye%20and%20Colourful%20World%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "11",
            chapterName: "Human Eye and Colourful World",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.5%5D%20-%20%5BHuman%20Eye%20and%20Colourful%20World%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "11",
            chapterName: "Human Eye and Colourful World",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.6%5D%20-%20%5BHuman%20Eye%20and%20Colourful%20World%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "12",
            chapterName: "Electricity",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter12/casestudyquestion.com%20-%20%5BAnswer%20Key%2012.1%5D%20-%20%5BElectricity%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "12",
            chapterName: "Electricity",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter12/casestudyquestion.com%20-%20%5BAnswer%20Key%2012.2%5D%20-%20%5BElectricity%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "12",
            chapterName: "Electricity",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter12/casestudyquestion.com%20-%20%5BAnswer%20Key%2012.3%5D%20-%20%5BElectricity%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "12",
            chapterName: "Electricity",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter12/casestudyquestion.com%20-%20%5BAnswer%20Key%2012.4%5D%20-%20%5BElectricity%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "12",
            chapterName: "Electricity",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter12/casestudyquestion.com%20-%20%5BAnswer%20Key%2012.5%5D%20-%20%5BElectricity%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "12",
            chapterName: "Electricity",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter12/casestudyquestion.com%20-%20%5BAnswer%20Key%2012.6%5D%20-%20%5BElectricity%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "12",
            chapterName: "Electricity",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter12/casestudyquestion.com%20-%20%5BWorksheet%2012.1%5D%20-%20%5BElectricity%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "12",
            chapterName: "Electricity",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter12/casestudyquestion.com%20-%20%5BWorksheet%2012.2%5D%20-%20%5BElectricity%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "12",
            chapterName: "Electricity",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter12/casestudyquestion.com%20-%20%5BWorksheet%2012.3%5D%20-%20%5BElectricity%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "12",
            chapterName: "Electricity",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter12/casestudyquestion.com%20-%20%5BWorksheet%2012.4%5D%20-%20%5BElectricity%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "12",
            chapterName: "Electricity",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter12/casestudyquestion.com%20-%20%5BWorksheet%2012.5%5D%20-%20%5BElectricity%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "12",
            chapterName: "Electricity",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter12/casestudyquestion.com%20-%20%5BWorksheet%2012.6%5D%20-%20%5BElectricity%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "13",
            chapterName: "Magnetic Effects of Electric Current",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter13/casestudyquestion.com%20-%20%5BAnswer%20Key%2013.1%5D%20-%20%5BMagnetic%20Effects%20of%20Electric%20Current%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "13",
            chapterName: "Magnetic Effects of Electric Current",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter13/casestudyquestion.com%20-%20%5BAnswer%20Key%2013.2%5D%20-%20%5BMagnetic%20Effects%20of%20Electric%20Current%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "13",
            chapterName: "Magnetic Effects of Electric Current",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter13/casestudyquestion.com%20-%20%5BAnswer%20Key%2013.3%5D%20-%20%5BMagnetic%20Effects%20of%20Electric%20Current%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "13",
            chapterName: "Magnetic Effects of Electric Current",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter13/casestudyquestion.com%20-%20%5BAnswer%20Key%2013.4%5D%20-%20%5BMagnetic%20Effects%20of%20Electric%20Current%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "13",
            chapterName: "Magnetic Effects of Electric Current",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 4,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter13/casestudyquestion.com%20-%20%5BAnswer%20Key%2013.5%5D%20-%20%5BMagnetic%20Effects%20of%20Electric%20Current%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "13",
            chapterName: "Magnetic Effects of Electric Current",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter13/casestudyquestion.com%20-%20%5BWorksheet%2013.1%5D%20-%20%5BMagnetic%20Effects%20of%20Electric%20Current%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "13",
            chapterName: "Magnetic Effects of Electric Current",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter13/casestudyquestion.com%20-%20%5BWorksheet%2013.2%5D%20-%20%5BMagnetic%20Effects%20of%20Electric%20Current%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "13",
            chapterName: "Magnetic Effects of Electric Current",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter13/casestudyquestion.com%20-%20%5BWorksheet%2013.3%5D%20-%20%5BMagnetic%20Effects%20of%20Electric%20Current%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "13",
            chapterName: "Magnetic Effects of Electric Current",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter13/casestudyquestion.com%20-%20%5BWorksheet%2013.4%5D%20-%20%5BMagnetic%20Effects%20of%20Electric%20Current%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "13",
            chapterName: "Magnetic Effects of Electric Current",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 4,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter13/casestudyquestion.com%20-%20%5BWorksheet%2013.5%5D%20-%20%5BMagnetic%20Effects%20of%20Electric%20Current%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "14",
            chapterName: "Sources Of Energy",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter14/casestudyquestion.com%20-%20%5BAnswer%20Key%2014.1%5D%20-%20%5BSources%20Of%20Energy%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "14",
            chapterName: "Sources Of Energy",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter14/casestudyquestion.com%20-%20%5BAnswer%20Key%2014.2%5D%20-%20%5BSources%20Of%20Energy%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "14",
            chapterName: "Sources Of Energy",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter14/casestudyquestion.com%20-%20%5BAnswer%20Key%2014.3%5D%20-%20%5BSources%20Of%20Energy%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "14",
            chapterName: "Sources Of Energy",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter14/casestudyquestion.com%20-%20%5BAnswer%20Key%2014.4%5D%20-%20%5BSources%20Of%20Energy%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "14",
            chapterName: "Sources Of Energy",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter14/casestudyquestion.com%20-%20%5BAnswer%20Key%2014.5%5D%20-%20%5BSources%20Of%20Energy%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "14",
            chapterName: "Sources Of Energy",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter14/casestudyquestion.com%20-%20%5BAnswer%20Key%2014.6%5D%20-%20%5BSources%20Of%20Energy%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "14",
            chapterName: "Sources Of Energy",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter14/casestudyquestion.com%20-%20%5BWorksheet%2014.1%5D%20-%20%5BSources%20Of%20Energy%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "14",
            chapterName: "Sources Of Energy",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter14/casestudyquestion.com%20-%20%5BWorksheet%2014.2%5D%20-%20%5BSources%20Of%20Energy%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "14",
            chapterName: "Sources Of Energy",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter14/casestudyquestion.com%20-%20%5BWorksheet%2014.3%5D%20-%20%5BSources%20Of%20Energy%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "14",
            chapterName: "Sources Of Energy",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter14/casestudyquestion.com%20-%20%5BWorksheet%2014.4%5D%20-%20%5BSources%20Of%20Energy%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "14",
            chapterName: "Sources Of Energy",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter14/casestudyquestion.com%20-%20%5BWorksheet%2014.5%5D%20-%20%5BSources%20Of%20Energy%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "14",
            chapterName: "Sources Of Energy",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter14/casestudyquestion.com%20-%20%5BWorksheet%2014.6%5D%20-%20%5BSources%20Of%20Energy%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "15",
            chapterName: "Our Environment",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter15/casestudyquestion.com%20-%20%5BAnswer%20Key%2015.1%5D%20-%20%5BOur%20Environment%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "15",
            chapterName: "Our Environment",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter15/casestudyquestion.com%20-%20%5BAnswer%20Key%2015.2%5D%20-%20%5BOur%20Environment%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "15",
            chapterName: "Our Environment",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter15/casestudyquestion.com%20-%20%5BAnswer%20Key%2015.3%5D%20-%20%5BOur%20Environment%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "15",
            chapterName: "Our Environment",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter15/casestudyquestion.com%20-%20%5BAnswer%20Key%2015.4%5D%20-%20%5BOur%20Environment%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "15",
            chapterName: "Our Environment",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter15/casestudyquestion.com%20-%20%5BAnswer%20Key%2015.5%5D%20-%20%5BOur%20Environment%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "15",
            chapterName: "Our Environment",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter15/casestudyquestion.com%20-%20%5BAnswer%20Key%2015.6%5D%20-%20%5BOur%20Environment%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "15",
            chapterName: "Our Environment",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter15/casestudyquestion.com%20-%20%5BWorksheet%2015.1%5D%20-%20%5BOur%20Environment%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "15",
            chapterName: "Our Environment",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter15/casestudyquestion.com%20-%20%5BWorksheet%2015.2%5D%20-%20%5BOur%20Environment%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "15",
            chapterName: "Our Environment",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter15/casestudyquestion.com%20-%20%5BWorksheet%2015.3%5D%20-%20%5BOur%20Environment%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "15",
            chapterName: "Our Environment",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter15/casestudyquestion.com%20-%20%5BWorksheet%2015.4%5D%20-%20%5BOur%20Environment%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "15",
            chapterName: "Our Environment",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter15/casestudyquestion.com%20-%20%5BWorksheet%2015.5%5D%20-%20%5BOur%20Environment%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "15",
            chapterName: "Our Environment",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter15/casestudyquestion.com%20-%20%5BWorksheet%2015.6%5D%20-%20%5BOur%20Environment%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "16",
            chapterName: "Sustainable Management of Natural Resources",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter16/casestudyquestion.com%20-%20%5BAnswer%20Key%2016.1%5D%20-%20%5BSustainable%20Management%20of%20Natural%20Resources%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "16",
            chapterName: "Sustainable Management of Natural Resources",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter16/casestudyquestion.com%20-%20%5BAnswer%20Key%2016.2%5D%20-%20%5BSustainable%20Management%20of%20Natural%20Resources%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "16",
            chapterName: "Sustainable Management of Natural Resources",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter16/casestudyquestion.com%20-%20%5BAnswer%20Key%2016.3%5D%20-%20%5BSustainable%20Management%20of%20Natural%20Resources%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "16",
            chapterName: "Sustainable Management of Natural Resources",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter16/casestudyquestion.com%20-%20%5BAnswer%20Key%2016.4%5D%20-%20%5BSustainable%20Management%20of%20Natural%20Resources%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "16",
            chapterName: "Sustainable Management of Natural Resources",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter16/casestudyquestion.com%20-%20%5BAnswer%20Key%2016.5%5D%20-%20%5BSustainable%20Management%20of%20Natural%20Resources%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "16",
            chapterName: "Sustainable Management of Natural Resources",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter16/casestudyquestion.com%20-%20%5BAnswer%20Key%2016.6%5D%20-%20%5BSustainable%20Management%20of%20Natural%20Resources%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "16",
            chapterName: "Sustainable Management of Natural Resources",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter16/casestudyquestion.com%20-%20%5BWorksheet%2016.1%5D%20-%20%5BSustainable%20Management%20of%20Natural%20Resources%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "16",
            chapterName: "Sustainable Management of Natural Resources",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter16/casestudyquestion.com%20-%20%5BWorksheet%2016.2%5D%20-%20%5BSustainable%20Management%20of%20Natural%20Resources%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "16",
            chapterName: "Sustainable Management of Natural Resources",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter16/casestudyquestion.com%20-%20%5BWorksheet%2016.3%5D%20-%20%5BSustainable%20Management%20of%20Natural%20Resources%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "16",
            chapterName: "Sustainable Management of Natural Resources",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter16/casestudyquestion.com%20-%20%5BWorksheet%2016.4%5D%20-%20%5BSustainable%20Management%20of%20Natural%20Resources%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "16",
            chapterName: "Sustainable Management of Natural Resources",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter16/casestudyquestion.com%20-%20%5BWorksheet%2016.5%5D%20-%20%5BSustainable%20Management%20of%20Natural%20Resources%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "16",
            chapterName: "Sustainable Management of Natural Resources",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter16/casestudyquestion.com%20-%20%5BWorksheet%2016.6%5D%20-%20%5BSustainable%20Management%20of%20Natural%20Resources%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "2",
            chapterName: "Acids, Bases and Salts",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.1%5D%20-%20%5BAcids%20Bases%20and%20Salts%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "2",
            chapterName: "Acids, Bases and Salts",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 11,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.2%5D%20-%20%5BAcids%20Bases%20and%20Salts%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "2",
            chapterName: "Acids, Bases and Salts",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.3%5D%20-%20%5BAcids%20Bases%20and%20Salts%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "2",
            chapterName: "Acids, Bases and Salts",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.4%5D%20-%20%5BAcids%20Bases%20and%20Salts%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "2",
            chapterName: "Acids, Bases and Salts",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.5%5D%20-%20%5BAcids%20Bases%20and%20Salts%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "2",
            chapterName: "Acids, Bases and Salts",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 4,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.6%5D%20-%20%5BAcids%20Bases%20and%20Salts%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "2",
            chapterName: "Acids, Bases and Salts",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.1%5D%20-%20%5BAcids%20Bases%20and%20Salts%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "2",
            chapterName: "Acids, Bases and Salts",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 11,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.2%5D%20-%20%5BAcids%20Bases%20and%20Salts%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "2",
            chapterName: "Acids, Bases and Salts",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.3%5D%20-%20%5BAcids%20Bases%20and%20Salts%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "2",
            chapterName: "Acids, Bases and Salts",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.4%5D%20-%20%5BAcids%20Bases%20and%20Salts%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "2",
            chapterName: "Acids, Bases and Salts",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.5%5D%20-%20%5BAcids%20Bases%20and%20Salts%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "2",
            chapterName: "Acids, Bases and Salts",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 4,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.6%5D%20-%20%5BAcids%20Bases%20and%20Salts%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "3",
            chapterName: "Metals and Non- Metals",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter3/casestudyquestion.com%20-%20%5BAnswer%20Key%203.1%5D%20-%20%5BMetals%20and%20Non%20Metals%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "3",
            chapterName: "Metals and Non- Metals",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter3/casestudyquestion.com%20-%20%5BAnswer%20Key%203.2%5D%20-%20%5BMetals%20and%20Non%20Metals%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "3",
            chapterName: "Metals and Non- Metals",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter3/casestudyquestion.com%20-%20%5BAnswer%20Key%203.4%5D%20-%20%5BMetals%20and%20Non%20Metals%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "3",
            chapterName: "Metals and Non- Metals",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter3/casestudyquestion.com%20-%20%5BAnswer%20Key%203.5%5D%20-%20%5BMetals%20and%20Non%20Metals%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "3",
            chapterName: "Metals and Non- Metals",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 4,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter3/casestudyquestion.com%20-%20%5BAnswer%20Key%203.6%5D%20-%20%5BMetals%20and%20Non%20Metals%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "3",
            chapterName: "Metals and Non- Metals",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter3/casestudyquestion.com%20-%20%5BWorksheet%203.1%5D%20-%20%5BMetals%20and%20Non%20Metals%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "3",
            chapterName: "Metals and Non- Metals",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter3/casestudyquestion.com%20-%20%5BWorksheet%203.2%5D%20-%20%5BMetals%20and%20Non%20Metals%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "3",
            chapterName: "Metals and Non- Metals",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter3/casestudyquestion.com%20-%20%5BWorksheet%203.4%5D%20-%20%5BMetals%20and%20Non%20Metals%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "3",
            chapterName: "Metals and Non- Metals",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter3/casestudyquestion.com%20-%20%5BWorksheet%203.5%5D%20-%20%5BMetals%20and%20Non%20Metals%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "3",
            chapterName: "Metals and Non- Metals",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 4,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter3/casestudyquestion.com%20-%20%5BWorksheet%203.6%5D%20-%20%5BMetals%20and%20Non%20Metals%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "4",
            chapterName: "Carbon And Its Compounds",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter4/casestudyquestion.com%20-%20%5BAnswer%20Key%204.1%5D%20-%20%5BCarbon%20And%20Its%20Compounds%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "4",
            chapterName: "Carbon And Its Compounds",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter4/casestudyquestion.com%20-%20%5BAnswer%20Key%204.2%5D%20-%20%5BCarbon%20And%20Its%20Compounds%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "4",
            chapterName: "Carbon And Its Compounds",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter4/casestudyquestion.com%20-%20%5BAnswer%20Key%204.3%5D%20-%20%5BCarbon%20And%20Its%20Compounds%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "4",
            chapterName: "Carbon And Its Compounds",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter4/casestudyquestion.com%20-%20%5BAnswer%20Key%204.4%5D%20-%20%5BCarbon%20And%20Its%20Compounds%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "4",
            chapterName: "Carbon And Its Compounds",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter4/casestudyquestion.com%20-%20%5BWorksheet%204.1%5D%20-%20%5BCarbon%20And%20Its%20Compounds%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "4",
            chapterName: "Carbon And Its Compounds",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter4/casestudyquestion.com%20-%20%5BWorksheet%204.2%5D%20-%20%5BCarbon%20And%20Its%20Compounds%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "4",
            chapterName: "Carbon And Its Compounds",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter4/casestudyquestion.com%20-%20%5BWorksheet%204.3%5D%20-%20%5BCarbon%20And%20Its%20Compounds%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "4",
            chapterName: "Carbon And Its Compounds",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter4/casestudyquestion.com%20-%20%5BWorksheet%204.4%5D%20-%20%5BCarbon%20And%20Its%20Compounds%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "5",
            chapterName: "Periodic Classification of Elements",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.1%5D%20-%20%5BPeriodic%20Classification%20of%20Elements%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "5",
            chapterName: "Periodic Classification of Elements",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.2%5D%20-%20%5BPeriodic%20Classification%20of%20Elements%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "5",
            chapterName: "Periodic Classification of Elements",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.3%5D%20-%20%5BPeriodic%20Classification%20of%20Elements%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "5",
            chapterName: "Periodic Classification of Elements",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.5%5D%20-%20%5BPeriodic%20Classification%20of%20Elements%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "5",
            chapterName: "Periodic Classification of Elements",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.6%5D%20-%20%5BPeriodic%20Classification%20of%20Elements%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "5",
            chapterName: "Periodic Classification of Elements",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.1%5D%20-%20%5BPeriodic%20Classification%20of%20Elements%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "5",
            chapterName: "Periodic Classification of Elements",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.2%5D%20-%20%5BPeriodic%20Classification%20of%20Elements%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "5",
            chapterName: "Periodic Classification of Elements",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.3%5D%20-%20%5BPeriodic%20Classification%20of%20Elements%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "5",
            chapterName: "Periodic Classification of Elements",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.5%5D%20-%20%5BPeriodic%20Classification%20of%20Elements%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "5",
            chapterName: "Periodic Classification of Elements",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.6%5D%20-%20%5BPeriodic%20Classification%20of%20Elements%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "6",
            chapterName: "Life Processes",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.1%5D%20-%20%5BLife%20Processes%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "6",
            chapterName: "Life Processes",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.2%5D%20-%20%5BLife%20Processes%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "6",
            chapterName: "Life Processes",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.3%5D%20-%20%5BLife%20Processes%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "6",
            chapterName: "Life Processes",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.4%5D%20-%20%5BLife%20Processes%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "6",
            chapterName: "Life Processes",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.5%5D%20-%20%5BLife%20Processes%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "6",
            chapterName: "Life Processes",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.6%5D%20-%20%5BLife%20Processes%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "6",
            chapterName: "Life Processes",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.1%5D%20-%20%5BLife%20Processes%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "6",
            chapterName: "Life Processes",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.2%5D%20-%20%5BLife%20Processes%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "6",
            chapterName: "Life Processes",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.3%5D%20-%20%5BLife%20Processes%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "6",
            chapterName: "Life Processes",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.4%5D%20-%20%5BLife%20Processes%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "6",
            chapterName: "Life Processes",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.5%5D%20-%20%5BLife%20Processes%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "6",
            chapterName: "Life Processes",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.6%5D%20-%20%5BLife%20Processes%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "7",
            chapterName: "Control And Coordination",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter7/casestudyquestion.com%20-%20%5BAnswer%20Key%207.1%5D%20-%20%5BControl%20And%20Coordination%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "7",
            chapterName: "Control And Coordination",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter7/casestudyquestion.com%20-%20%5BAnswer%20Key%207.2%5D%20-%20%5BControl%20And%20Coordination%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "7",
            chapterName: "Control And Coordination",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter7/casestudyquestion.com%20-%20%5BAnswer%20Key%207.3%5D%20-%20%5BControl%20And%20Coordination%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "7",
            chapterName: "Control And Coordination",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter7/casestudyquestion.com%20-%20%5BAnswer%20Key%207.4%5D%20-%20%5BControl%20And%20Coordination%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "7",
            chapterName: "Control And Coordination",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter7/casestudyquestion.com%20-%20%5BAnswer%20Key%207.5%5D%20-%20%5BControl%20And%20Coordination%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "7",
            chapterName: "Control And Coordination",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter7/casestudyquestion.com%20-%20%5BAnswer%20Key%207.6%5D%20-%20%5BControl%20And%20Coordination%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "7",
            chapterName: "Control And Coordination",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter7/casestudyquestion.com%20-%20%5BWorksheet%207.1%5D%20-%20%5BControl%20And%20Coordination%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "7",
            chapterName: "Control And Coordination",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter7/casestudyquestion.com%20-%20%5BWorksheet%207.2%5D%20-%20%5BControl%20And%20Coordination%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "7",
            chapterName: "Control And Coordination",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter7/casestudyquestion.com%20-%20%5BWorksheet%207.3%5D%20-%20%5BControl%20And%20Coordination%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "7",
            chapterName: "Control And Coordination",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter7/casestudyquestion.com%20-%20%5BWorksheet%207.4%5D%20-%20%5BControl%20And%20Coordination%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "7",
            chapterName: "Control And Coordination",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter7/casestudyquestion.com%20-%20%5BWorksheet%207.5%5D%20-%20%5BControl%20And%20Coordination%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "7",
            chapterName: "Control And Coordination",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter7/casestudyquestion.com%20-%20%5BWorksheet%207.6%5D%20-%20%5BControl%20And%20Coordination%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "8",
            chapterName: "How do Organisms Reproduce?",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter8/casestudyquestion.com%20-%20%5BAnswer%20Key%208.1%5D%20-%20%5BHow%20do%20Organisms%20Reproduce%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "8",
            chapterName: "How do Organisms Reproduce?",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter8/casestudyquestion.com%20-%20%5BAnswer%20Key%208.2%5D%20-%20%5BHow%20do%20Organisms%20Reproduce%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "8",
            chapterName: "How do Organisms Reproduce?",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter8/casestudyquestion.com%20-%20%5BAnswer%20Key%208.3%5D%20-%20%5BHow%20do%20Organisms%20Reproduce%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "8",
            chapterName: "How do Organisms Reproduce?",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter8/casestudyquestion.com%20-%20%5BAnswer%20Key%208.4%5D%20-%20%5BHow%20do%20Organisms%20Reproduce%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "8",
            chapterName: "How do Organisms Reproduce?",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter8/casestudyquestion.com%20-%20%5BAnswer%20Key%208.5%5D%20-%20%5BHow%20do%20Organisms%20Reproduce%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "8",
            chapterName: "How do Organisms Reproduce?",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter8/casestudyquestion.com%20-%20%5BAnswer%20Key%208.6%5D%20-%20%5BHow%20do%20Organisms%20Reproduce%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "8",
            chapterName: "How do Organisms Reproduce?",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter8/casestudyquestion.com%20-%20%5BWorksheet%208.1%5D%20-%20%5BHow%20do%20Organisms%20Reproduce%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "8",
            chapterName: "How do Organisms Reproduce?",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter8/casestudyquestion.com%20-%20%5BWorksheet%208.2%5D%20-%20%5BHow%20do%20Organisms%20Reproduce%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "8",
            chapterName: "How do Organisms Reproduce?",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter8/casestudyquestion.com%20-%20%5BWorksheet%208.3%5D%20-%20%5BHow%20do%20Organisms%20Reproduce%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "8",
            chapterName: "How do Organisms Reproduce?",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter8/casestudyquestion.com%20-%20%5BWorksheet%208.4%5D%20-%20%5BHow%20do%20Organisms%20Reproduce%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "8",
            chapterName: "How do Organisms Reproduce?",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter8/casestudyquestion.com%20-%20%5BWorksheet%208.5%5D%20-%20%5BHow%20do%20Organisms%20Reproduce%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "8",
            chapterName: "How do Organisms Reproduce?",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter8/casestudyquestion.com%20-%20%5BWorksheet%208.6%5D%20-%20%5BHow%20do%20Organisms%20Reproduce%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "9",
            chapterName: "Heridity and Evolution",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.1%5D%20-%20%5BHeridity%20and%20Evolution%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "9",
            chapterName: "Heridity and Evolution",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.2%5D%20-%20%5BHeridity%20and%20Evolution%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "9",
            chapterName: "Heridity and Evolution",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.3%5D%20-%20%5BHeridity%20and%20Evolution%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "9",
            chapterName: "Heridity and Evolution",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.4%5D%20-%20%5BHeridity%20and%20Evolution%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "9",
            chapterName: "Heridity and Evolution",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.5%5D%20-%20%5BHeridity%20and%20Evolution%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "9",
            chapterName: "Heridity and Evolution",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.6%5D%20-%20%5BHeridity%20and%20Evolution%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "9",
            chapterName: "Heridity and Evolution",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.1%5D%20-%20%5BHeridity%20and%20Evolution%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "9",
            chapterName: "Heridity and Evolution",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.2%5D%20-%20%5BHeridity%20and%20Evolution%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "9",
            chapterName: "Heridity and Evolution",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.3%5D%20-%20%5BHeridity%20and%20Evolution%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "9",
            chapterName: "Heridity and Evolution",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.4%5D%20-%20%5BHeridity%20and%20Evolution%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "9",
            chapterName: "Heridity and Evolution",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.5%5D%20-%20%5BHeridity%20and%20Evolution%5D.pdf",
        },
        {
            subject: "Science",
            chapterInd: "9",
            chapterName: "Heridity and Evolution",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                    {
                        noOfQuestions: 5,
                    },
                ],
                noOfPassage: 5,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Science/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.6%5D%20-%20%5BHeridity%20and%20Evolution%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "1",
            chapterName: "The Rise of Nationalism in Europe",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.1%5D%20-%20%5BThe%20Rise%20of%20Nationalism%20in%20Europe%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "1",
            chapterName: "The Rise of Nationalism in Europe",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.2%5D%20-%20%5BThe%20Rise%20of%20Nationalism%20in%20Europe%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "1",
            chapterName: "The Rise of Nationalism in Europe",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.3%5D%20-%20%5BThe%20Rise%20of%20Nationalism%20in%20Europe%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "1",
            chapterName: "The Rise of Nationalism in Europe",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.4%5D%20-%20%5BThe%20Rise%20of%20Nationalism%20in%20Europe%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "1",
            chapterName: "The Rise of Nationalism in Europe",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.5%5D%20-%20%5BThe%20Rise%20of%20Nationalism%20in%20Europe%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "1",
            chapterName: "The Rise of Nationalism in Europe",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.6%5D%20-%20%5BThe%20Rise%20of%20Nationalism%20in%20Europe%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "1",
            chapterName: "The Rise of Nationalism in Europe",
            setInd: "7 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.7%5D%20-%20%5BThe%20Rise%20of%20Nationalism%20in%20Europe%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "1",
            chapterName: "The Rise of Nationalism in Europe",
            setInd: "8 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter1/casestudyquestion.com%20-%20%5BAnswer%20Key%201.8%5D%20-%20%5BThe%20Rise%20of%20Nationalism%20in%20Europe%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "1",
            chapterName: "The Rise of Nationalism in Europe",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.1%5D%20-%20%5BThe%20Rise%20of%20Nationalism%20in%20Europe%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "1",
            chapterName: "The Rise of Nationalism in Europe",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.2%5D%20-%20%5BThe%20Rise%20of%20Nationalism%20in%20Europe%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "1",
            chapterName: "The Rise of Nationalism in Europe",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.3%5D%20-%20%5BThe%20Rise%20of%20Nationalism%20in%20Europe%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "1",
            chapterName: "The Rise of Nationalism in Europe",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.4%5D%20-%20%5BThe%20Rise%20of%20Nationalism%20in%20Europe%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "1",
            chapterName: "The Rise of Nationalism in Europe",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.5%5D%20-%20%5BThe%20Rise%20of%20Nationalism%20in%20Europe%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "1",
            chapterName: "The Rise of Nationalism in Europe",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.6%5D%20-%20%5BThe%20Rise%20of%20Nationalism%20in%20Europe%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "1",
            chapterName: "The Rise of Nationalism in Europe",
            setInd: "7 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.7%5D%20-%20%5BThe%20Rise%20of%20Nationalism%20in%20Europe%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "1",
            chapterName: "The Rise of Nationalism in Europe",
            setInd: "8 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter1/casestudyquestion.com%20-%20%5BWorksheet%201.8%5D%20-%20%5BThe%20Rise%20of%20Nationalism%20in%20Europe%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "10",
            chapterName: "Minerals and Energy Resources",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.1%5D%20-%20%5BMinerals%20and%20Energy%20Resources%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "10",
            chapterName: "Minerals and Energy Resources",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.2%5D%20-%20%5BMinerals%20and%20Energy%20Resources%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "10",
            chapterName: "Minerals and Energy Resources",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.3%5D%20-%20%5BMinerals%20and%20Energy%20Resources%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "10",
            chapterName: "Minerals and Energy Resources",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.4%5D%20-%20%5BMinerals%20and%20Energy%20Resources%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "10",
            chapterName: "Minerals and Energy Resources",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.5%5D%20-%20%5BMinerals%20and%20Energy%20Resources%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "10",
            chapterName: "Minerals and Energy Resources",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.6%5D%20-%20%5BMinerals%20and%20Energy%20Resources%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "10",
            chapterName: "Minerals and Energy Resources",
            setInd: "7 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.7%5D%20-%20%5BMinerals%20and%20Energy%20Resources%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "10",
            chapterName: "Minerals and Energy Resources",
            setInd: "8 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.8%5D%20-%20%5BMinerals%20and%20Energy%20Resources%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "10",
            chapterName: "Minerals and Energy Resources",
            setInd: "9 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter10/casestudyquestion.com%20-%20%5BAnswer%20Key%2010.9%5D%20-%20%5BMinerals%20and%20Energy%20Resources%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "10",
            chapterName: "Minerals and Energy Resources",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.1%5D%20-%20%5BMinerals%20and%20Energy%20Resources%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "10",
            chapterName: "Minerals and Energy Resources",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.2%5D%20-%20%5BMinerals%20and%20Energy%20Resources%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "10",
            chapterName: "Minerals and Energy Resources",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.3%5D%20-%20%5BMinerals%20and%20Energy%20Resources%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "10",
            chapterName: "Minerals and Energy Resources",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.4%5D%20-%20%5BMinerals%20and%20Energy%20Resources%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "10",
            chapterName: "Minerals and Energy Resources",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.5%5D%20-%20%5BMinerals%20and%20Energy%20Resources%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "10",
            chapterName: "Minerals and Energy Resources",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.6%5D%20-%20%5BMinerals%20and%20Energy%20Resources%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "10",
            chapterName: "Minerals and Energy Resources",
            setInd: "7 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.7%5D%20-%20%5BMinerals%20and%20Energy%20Resources%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "10",
            chapterName: "Minerals and Energy Resources",
            setInd: "8 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.8%5D%20-%20%5BMinerals%20and%20Energy%20Resources%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "10",
            chapterName: "Minerals and Energy Resources",
            setInd: "9 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter10/casestudyquestion.com%20-%20%5BWorksheet%2010.9%5D%20-%20%5BMinerals%20and%20Energy%20Resources%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "11",
            chapterName: "Manufacturing Industries",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.1%5D%20-%20%5BManufacturing%20Industries%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "11",
            chapterName: "Manufacturing Industries",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.2%5D%20-%20%5BManufacturing%20Industries%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "11",
            chapterName: "Manufacturing Industries",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.3%5D%20-%20%5BManufacturing%20Industries%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "11",
            chapterName: "Manufacturing Industries",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.4%5D%20-%20%5BManufacturing%20Industries%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "11",
            chapterName: "Manufacturing Industries",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.5%5D%20-%20%5BManufacturing%20Industries%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "11",
            chapterName: "Manufacturing Industries",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.6%5D%20-%20%5BManufacturing%20Industries%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "11",
            chapterName: "Manufacturing Industries",
            setInd: "7 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.7%5D%20-%20%5BManufacturing%20Industries%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "11",
            chapterName: "Manufacturing Industries",
            setInd: "8 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.8%5D%20-%20%5BManufacturing%20Industries%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "11",
            chapterName: "Manufacturing Industries",
            setInd: "9 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter11/casestudyquestion.com%20-%20%5BAnswer%20Key%2011.9%5D%20-%20%5BManufacturing%20Industries%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "11",
            chapterName: "Manufacturing Industries",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.1%5D%20-%20%5BManufacturing%20Industries%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "11",
            chapterName: "Manufacturing Industries",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.2%5D%20-%20%5BManufacturing%20Industries%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "11",
            chapterName: "Manufacturing Industries",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.3%5D%20-%20%5BManufacturing%20Industries%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "11",
            chapterName: "Manufacturing Industries",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.4%5D%20-%20%5BManufacturing%20Industries%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "11",
            chapterName: "Manufacturing Industries",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.5%5D%20-%20%5BManufacturing%20Industries%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "11",
            chapterName: "Manufacturing Industries",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.6%5D%20-%20%5BManufacturing%20Industries%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "11",
            chapterName: "Manufacturing Industries",
            setInd: "7 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.7%5D%20-%20%5BManufacturing%20Industries%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "11",
            chapterName: "Manufacturing Industries",
            setInd: "8 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.8%5D%20-%20%5BManufacturing%20Industries%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "11",
            chapterName: "Manufacturing Industries",
            setInd: "9 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter11/casestudyquestion.com%20-%20%5BWorksheet%2011.9%5D%20-%20%5BManufacturing%20Industries%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "12",
            chapterName: "Lifelines of National Economy",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter12/casestudyquestion.com%20-%20%5BAnswer%20Key%2012.1%5D%20-%20%5BLifelines%20of%20National%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "12",
            chapterName: "Lifelines of National Economy",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter12/casestudyquestion.com%20-%20%5BAnswer%20Key%2012.2%5D%20-%20%5BLifelines%20of%20National%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "12",
            chapterName: "Lifelines of National Economy",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter12/casestudyquestion.com%20-%20%5BAnswer%20Key%2012.3%5D%20-%20%5BLifelines%20of%20National%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "12",
            chapterName: "Lifelines of National Economy",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter12/casestudyquestion.com%20-%20%5BAnswer%20Key%2012.4%5D%20-%20%5BLifelines%20of%20National%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "12",
            chapterName: "Lifelines of National Economy",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter12/casestudyquestion.com%20-%20%5BAnswer%20Key%2012.5%5D%20-%20%5BLifelines%20of%20National%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "12",
            chapterName: "Lifelines of National Economy",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter12/casestudyquestion.com%20-%20%5BWorksheet%2012.1%5D%20-%20%5BLifelines%20of%20National%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "12",
            chapterName: "Lifelines of National Economy",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter12/casestudyquestion.com%20-%20%5BWorksheet%2012.2%5D%20-%20%5BLifelines%20of%20National%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "12",
            chapterName: "Lifelines of National Economy",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter12/casestudyquestion.com%20-%20%5BWorksheet%2012.3%5D%20-%20%5BLifelines%20of%20National%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "12",
            chapterName: "Lifelines of National Economy",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter12/casestudyquestion.com%20-%20%5BWorksheet%2012.4%5D%20-%20%5BLifelines%20of%20National%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "12",
            chapterName: "Lifelines of National Economy",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter12/casestudyquestion.com%20-%20%5BWorksheet%2012.5%5D%20-%20%5BLifelines%20of%20National%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "13",
            chapterName: "Power Sharing",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter13/casestudyquestion.com%20-%20%5BAnswer%20Key%2013.1%5D%20-%20%5BPower%20Sharing%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "13",
            chapterName: "Power Sharing",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter13/casestudyquestion.com%20-%20%5BAnswer%20Key%2013.2%5D%20-%20%5BPower%20Sharing%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "13",
            chapterName: "Power Sharing",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter13/casestudyquestion.com%20-%20%5BAnswer%20Key%2013.3%5D%20-%20%5BPower%20Sharing%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "13",
            chapterName: "Power Sharing",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter13/casestudyquestion.com%20-%20%5BAnswer%20Key%2013.4%5D%20-%20%5BPower%20Sharing%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "13",
            chapterName: "Power Sharing",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter13/casestudyquestion.com%20-%20%5BAnswer%20Key%2013.5%5D%20-%20%5BPower%20Sharing%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "13",
            chapterName: "Power Sharing",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter13/casestudyquestion.com%20-%20%5BWorksheet%2013.1%5D%20-%20%5BPower%20Sharing%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "13",
            chapterName: "Power Sharing",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter13/casestudyquestion.com%20-%20%5BWorksheet%2013.2%5D%20-%20%5BPower%20Sharing%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "13",
            chapterName: "Power Sharing",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter13/casestudyquestion.com%20-%20%5BWorksheet%2013.3%5D%20-%20%5BPower%20Sharing%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "13",
            chapterName: "Power Sharing",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter13/casestudyquestion.com%20-%20%5BWorksheet%2013.4%5D%20-%20%5BPower%20Sharing%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "13",
            chapterName: "Power Sharing",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter13/casestudyquestion.com%20-%20%5BWorksheet%2013.5%5D%20-%20%5BPower%20Sharing%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "14",
            chapterName: "Federalism",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter14/casestudyquestion.com%20-%20%5BAnswer%20Key%2014.1%5D%20-%20%5BFederalism%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "14",
            chapterName: "Federalism",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter14/casestudyquestion.com%20-%20%5BAnswer%20Key%2014.2%5D%20-%20%5BFederalism%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "14",
            chapterName: "Federalism",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter14/casestudyquestion.com%20-%20%5BAnswer%20Key%2014.3%5D%20-%20%5BFederalism%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "14",
            chapterName: "Federalism",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter14/casestudyquestion.com%20-%20%5BAnswer%20Key%2014.4%5D%20-%20%5BFederalism%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "14",
            chapterName: "Federalism",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter14/casestudyquestion.com%20-%20%5BWorksheet%2014.1%5D%20-%20%5BFederalism%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "14",
            chapterName: "Federalism",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter14/casestudyquestion.com%20-%20%5BWorksheet%2014.2%5D%20-%20%5BFederalism%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "14",
            chapterName: "Federalism",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter14/casestudyquestion.com%20-%20%5BWorksheet%2014.3%5D%20-%20%5BFederalism%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "14",
            chapterName: "Federalism",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter14/casestudyquestion.com%20-%20%5BWorksheet%2014.4%5D%20-%20%5BFederalism%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "15",
            chapterName: "Democracy and Diversity",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter15/casestudyquestion.com%20-%20%5BAnswer%20Key%2015.1%5D%20-%20%5BDemocracy%20and%20Diversity%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "15",
            chapterName: "Democracy and Diversity",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter15/casestudyquestion.com%20-%20%5BWorksheet%2015.1%5D%20-%20%5BDemocracy%20and%20Diversity%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "16",
            chapterName: "Gender, Religion and Caste",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter16/casestudyquestion.com%20-%20%5BAnswer%20Key%2016.1%5D%20-%20%5BGender%20Religion%20and%20Caste%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "16",
            chapterName: "Gender, Religion and Caste",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter16/casestudyquestion.com%20-%20%5BAnswer%20Key%2016.2%5D%20-%20%5BGender%20Religion%20and%20Caste%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "16",
            chapterName: "Gender, Religion and Caste",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter16/casestudyquestion.com%20-%20%5BAnswer%20Key%2016.3%5D%20-%20%5BGender%20Religion%20and%20Caste%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "16",
            chapterName: "Gender, Religion and Caste",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter16/casestudyquestion.com%20-%20%5BAnswer%20Key%2016.4%5D%20-%20%5BGender%20Religion%20and%20Caste%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "16",
            chapterName: "Gender, Religion and Caste",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter16/casestudyquestion.com%20-%20%5BAnswer%20Key%2016.5%5D%20-%20%5BGender%20Religion%20and%20Caste%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "16",
            chapterName: "Gender, Religion and Caste",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter16/casestudyquestion.com%20-%20%5BAnswer%20Key%2016.6%5D%20-%20%5BGender%20Religion%20and%20Caste%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "16",
            chapterName: "Gender, Religion and Caste",
            setInd: "7 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter16/casestudyquestion.com%20-%20%5BAnswer%20Key%2016.7%5D%20-%20%5BGender%20Religion%20and%20Caste%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "16",
            chapterName: "Gender, Religion and Caste",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter16/casestudyquestion.com%20-%20%5BWorksheet%2016.1%5D%20-%20%5BGender%20Religion%20and%20Caste%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "16",
            chapterName: "Gender, Religion and Caste",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter16/casestudyquestion.com%20-%20%5BWorksheet%2016.2%5D%20-%20%5BGender%20Religion%20and%20Caste%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "16",
            chapterName: "Gender, Religion and Caste",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter16/casestudyquestion.com%20-%20%5BWorksheet%2016.3%5D%20-%20%5BGender%20Religion%20and%20Caste%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "16",
            chapterName: "Gender, Religion and Caste",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter16/casestudyquestion.com%20-%20%5BWorksheet%2016.4%5D%20-%20%5BGender%20Religion%20and%20Caste%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "16",
            chapterName: "Gender, Religion and Caste",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter16/casestudyquestion.com%20-%20%5BWorksheet%2016.5%5D%20-%20%5BGender%20Religion%20and%20Caste%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "16",
            chapterName: "Gender, Religion and Caste",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter16/casestudyquestion.com%20-%20%5BWorksheet%2016.6%5D%20-%20%5BGender%20Religion%20and%20Caste%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "16",
            chapterName: "Gender, Religion and Caste",
            setInd: "7 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter16/casestudyquestion.com%20-%20%5BWorksheet%2016.7%5D%20-%20%5BGender%20Religion%20and%20Caste%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "17",
            chapterName: "Popular struggles and Movements",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter17/casestudyquestion.com%20-%20%5BAnswer%20Key%2017.1%5D%20-%20%5BPopular%20struggles%20and%20Movements%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "17",
            chapterName: "Popular struggles and Movements",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter17/casestudyquestion.com%20-%20%5BWorksheet%2017.1%5D%20-%20%5BPopular%20struggles%20and%20Movements%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "18",
            chapterName: "Political Parties",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter18/casestudyquestion.com%20-%20%5BAnswer%20Key%2018.1%5D%20-%20%5BPolitical%20Parties%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "18",
            chapterName: "Political Parties",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter18/casestudyquestion.com%20-%20%5BAnswer%20Key%2018.2%5D%20-%20%5BPolitical%20Parties%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "18",
            chapterName: "Political Parties",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter18/casestudyquestion.com%20-%20%5BAnswer%20Key%2018.3%5D%20-%20%5BPolitical%20Parties%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "18",
            chapterName: "Political Parties",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter18/casestudyquestion.com%20-%20%5BAnswer%20Key%2018.4%5D%20-%20%5BPolitical%20Parties%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "18",
            chapterName: "Political Parties",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter18/casestudyquestion.com%20-%20%5BAnswer%20Key%2018.5%5D%20-%20%5BPolitical%20Parties%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "18",
            chapterName: "Political Parties",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter18/casestudyquestion.com%20-%20%5BAnswer%20Key%2018.6%5D%20-%20%5BPolitical%20Parties%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "18",
            chapterName: "Political Parties",
            setInd: "7 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter18/casestudyquestion.com%20-%20%5BAnswer%20Key%2018.7%5D%20-%20%5BPolitical%20Parties%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "18",
            chapterName: "Political Parties",
            setInd: "8 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter18/casestudyquestion.com%20-%20%5BAnswer%20Key%2018.8%5D%20-%20%5BPolitical%20Parties%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "18",
            chapterName: "Political Parties",
            setInd: "9 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter18/casestudyquestion.com%20-%20%5BAnswer%20Key%2018.9%5D%20-%20%5BPolitical%20Parties%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "18",
            chapterName: "Political Parties",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter18/casestudyquestion.com%20-%20%5BWorksheet%2018.1%5D%20-%20%5BPolitical%20Parties%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "18",
            chapterName: "Political Parties",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter18/casestudyquestion.com%20-%20%5BWorksheet%2018.2%5D%20-%20%5BPolitical%20Parties%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "18",
            chapterName: "Political Parties",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter18/casestudyquestion.com%20-%20%5BWorksheet%2018.3%5D%20-%20%5BPolitical%20Parties%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "18",
            chapterName: "Political Parties",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter18/casestudyquestion.com%20-%20%5BWorksheet%2018.4%5D%20-%20%5BPolitical%20Parties%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "18",
            chapterName: "Political Parties",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter18/casestudyquestion.com%20-%20%5BWorksheet%2018.5%5D%20-%20%5BPolitical%20Parties%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "18",
            chapterName: "Political Parties",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter18/casestudyquestion.com%20-%20%5BWorksheet%2018.6%5D%20-%20%5BPolitical%20Parties%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "18",
            chapterName: "Political Parties",
            setInd: "7 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter18/casestudyquestion.com%20-%20%5BWorksheet%2018.7%5D%20-%20%5BPolitical%20Parties%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "18",
            chapterName: "Political Parties",
            setInd: "8 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter18/casestudyquestion.com%20-%20%5BWorksheet%2018.8%5D%20-%20%5BPolitical%20Parties%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "18",
            chapterName: "Political Parties",
            setInd: "9 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter18/casestudyquestion.com%20-%20%5BWorksheet%2018.9%5D%20-%20%5BPolitical%20Parties%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "19",
            chapterName: "Outcomes of Democracy",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter19/casestudyquestion.com%20-%20%5BAnswer%20Key%2019.1%5D%20-%20%5BOutcomes%20of%20Democracy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "19",
            chapterName: "Outcomes of Democracy",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter19/casestudyquestion.com%20-%20%5BAnswer%20Key%2019.2%5D%20-%20%5BOutcomes%20of%20Democracy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "19",
            chapterName: "Outcomes of Democracy",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter19/casestudyquestion.com%20-%20%5BAnswer%20Key%2019.3%5D%20-%20%5BOutcomes%20of%20Democracy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "19",
            chapterName: "Outcomes of Democracy",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter19/casestudyquestion.com%20-%20%5BAnswer%20Key%2019.4%5D%20-%20%5BOutcomes%20of%20Democracy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "19",
            chapterName: "Outcomes of Democracy",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter19/casestudyquestion.com%20-%20%5BAnswer%20Key%2019.5%5D%20-%20%5BOutcomes%20of%20Democracy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "19",
            chapterName: "Outcomes of Democracy",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter19/casestudyquestion.com%20-%20%5BAnswer%20Key%2019.6%5D%20-%20%5BOutcomes%20of%20Democracy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "19",
            chapterName: "Outcomes of Democracy",
            setInd: "7 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter19/casestudyquestion.com%20-%20%5BAnswer%20Key%2019.7%5D%20-%20%5BOutcomes%20of%20Democracy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "19",
            chapterName: "Outcomes of Democracy",
            setInd: "8 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter19/casestudyquestion.com%20-%20%5BAnswer%20Key%2019.8%5D%20-%20%5BOutcomes%20of%20Democracy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "19",
            chapterName: "Outcomes of Democracy",
            setInd: "9 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter19/casestudyquestion.com%20-%20%5BAnswer%20Key%2019.9%5D%20-%20%5BOutcomes%20of%20Democracy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "19",
            chapterName: "Outcomes of Democracy",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter19/casestudyquestion.com%20-%20%5BWorksheet%2019.1%5D%20-%20%5BOutcomes%20of%20Democracy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "19",
            chapterName: "Outcomes of Democracy",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter19/casestudyquestion.com%20-%20%5BWorksheet%2019.2%5D%20-%20%5BOutcomes%20of%20Democracy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "19",
            chapterName: "Outcomes of Democracy",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter19/casestudyquestion.com%20-%20%5BWorksheet%2019.3%5D%20-%20%5BOutcomes%20of%20Democracy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "19",
            chapterName: "Outcomes of Democracy",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter19/casestudyquestion.com%20-%20%5BWorksheet%2019.4%5D%20-%20%5BOutcomes%20of%20Democracy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "19",
            chapterName: "Outcomes of Democracy",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter19/casestudyquestion.com%20-%20%5BWorksheet%2019.5%5D%20-%20%5BOutcomes%20of%20Democracy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "19",
            chapterName: "Outcomes of Democracy",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter19/casestudyquestion.com%20-%20%5BWorksheet%2019.6%5D%20-%20%5BOutcomes%20of%20Democracy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "19",
            chapterName: "Outcomes of Democracy",
            setInd: "7 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter19/casestudyquestion.com%20-%20%5BWorksheet%2019.7%5D%20-%20%5BOutcomes%20of%20Democracy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "19",
            chapterName: "Outcomes of Democracy",
            setInd: "8 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter19/casestudyquestion.com%20-%20%5BWorksheet%2019.8%5D%20-%20%5BOutcomes%20of%20Democracy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "19",
            chapterName: "Outcomes of Democracy",
            setInd: "9 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter19/casestudyquestion.com%20-%20%5BWorksheet%2019.9%5D%20-%20%5BOutcomes%20of%20Democracy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "2",
            chapterName: "Nationalism in India",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.1%5D%20-%20%5BNationalism%20in%20India%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "2",
            chapterName: "Nationalism in India",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.2%5D%20-%20%5BNationalism%20in%20India%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "2",
            chapterName: "Nationalism in India",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.3%5D%20-%20%5BNationalism%20in%20India%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "2",
            chapterName: "Nationalism in India",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.4%5D%20-%20%5BNationalism%20in%20India%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "2",
            chapterName: "Nationalism in India",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.5%5D%20-%20%5BNationalism%20in%20India%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "2",
            chapterName: "Nationalism in India",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.6%5D%20-%20%5BNationalism%20in%20India%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "2",
            chapterName: "Nationalism in India",
            setInd: "7 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.7%5D%20-%20%5BNationalism%20in%20India%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "2",
            chapterName: "Nationalism in India",
            setInd: "8 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter2/casestudyquestion.com%20-%20%5BAnswer%20Key%202.8%5D%20-%20%5BNationalism%20in%20India%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "2",
            chapterName: "Nationalism in India",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.1%5D%20-%20%5BNationalism%20in%20India%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "2",
            chapterName: "Nationalism in India",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.2%5D%20-%20%5BNationalism%20in%20India%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "2",
            chapterName: "Nationalism in India",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.3%5D%20-%20%5BNationalism%20in%20India%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "2",
            chapterName: "Nationalism in India",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.4%5D%20-%20%5BNationalism%20in%20India%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "2",
            chapterName: "Nationalism in India",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.5%5D%20-%20%5BNationalism%20in%20India%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "2",
            chapterName: "Nationalism in India",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.6%5D%20-%20%5BNationalism%20in%20India%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "2",
            chapterName: "Nationalism in India",
            setInd: "7 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.7%5D%20-%20%5BNationalism%20in%20India%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "2",
            chapterName: "Nationalism in India",
            setInd: "8 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter2/casestudyquestion.com%20-%20%5BWorksheet%202.8%5D%20-%20%5BNationalism%20in%20India%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "20",
            chapterName: "Challenges to Democracy",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter20/casestudyquestion.com%20-%20%5BAnswer%20Key%2020.1%5D%20-%20%5BChallenges%20to%20Democracy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "20",
            chapterName: "Challenges to Democracy",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter20/casestudyquestion.com%20-%20%5BWorksheet%2020.1%5D%20-%20%5BChallenges%20to%20Democracy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "21",
            chapterName: "Development",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter21/casestudyquestion.com%20-%20%5BAnswer%20Key%2021.1%5D%20-%20%5BDevelopment%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "21",
            chapterName: "Development",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter21/casestudyquestion.com%20-%20%5BAnswer%20Key%2021.2%5D%20-%20%5BDevelopment%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "21",
            chapterName: "Development",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter21/casestudyquestion.com%20-%20%5BAnswer%20Key%2021.3%5D%20-%20%5BDevelopment%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "21",
            chapterName: "Development",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter21/casestudyquestion.com%20-%20%5BAnswer%20Key%2021.4%5D%20-%20%5BDevelopment%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "21",
            chapterName: "Development",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter21/casestudyquestion.com%20-%20%5BAnswer%20Key%2021.5%5D%20-%20%5BDevelopment%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "21",
            chapterName: "Development",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter21/casestudyquestion.com%20-%20%5BAnswer%20Key%2021.6%5D%20-%20%5BDevelopment%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "21",
            chapterName: "Development",
            setInd: "7 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter21/casestudyquestion.com%20-%20%5BAnswer%20Key%2021.7%5D%20-%20%5BDevelopment%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "21",
            chapterName: "Development",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter21/casestudyquestion.com%20-%20%5BWorksheet%2021.1%5D%20-%20%5BDevelopment%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "21",
            chapterName: "Development",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter21/casestudyquestion.com%20-%20%5BWorksheet%2021.2%5D%20-%20%5BDevelopment%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "21",
            chapterName: "Development",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter21/casestudyquestion.com%20-%20%5BWorksheet%2021.3%5D%20-%20%5BDevelopment%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "21",
            chapterName: "Development",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter21/casestudyquestion.com%20-%20%5BWorksheet%2021.4%5D%20-%20%5BDevelopment%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "21",
            chapterName: "Development",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter21/casestudyquestion.com%20-%20%5BWorksheet%2021.5%5D%20-%20%5BDevelopment%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "21",
            chapterName: "Development",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter21/casestudyquestion.com%20-%20%5BWorksheet%2021.6%5D%20-%20%5BDevelopment%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "21",
            chapterName: "Development",
            setInd: "7 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter21/casestudyquestion.com%20-%20%5BWorksheet%2021.7%5D%20-%20%5BDevelopment%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "22",
            chapterName: "Sectors of the Indian Economy",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter22/casestudyquestion.com%20-%20%5BAnswer%20Key%2022.1%5D%20-%20%5BSectors%20of%20the%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "22",
            chapterName: "Sectors of the Indian Economy",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter22/casestudyquestion.com%20-%20%5BAnswer%20Key%2022.2%5D%20-%20%5BSectors%20of%20the%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "22",
            chapterName: "Sectors of the Indian Economy",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter22/casestudyquestion.com%20-%20%5BAnswer%20Key%2022.3%5D%20-%20%5BSectors%20of%20the%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "22",
            chapterName: "Sectors of the Indian Economy",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter22/casestudyquestion.com%20-%20%5BAnswer%20Key%2022.4%5D%20-%20%5BSectors%20of%20the%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "22",
            chapterName: "Sectors of the Indian Economy",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter22/casestudyquestion.com%20-%20%5BAnswer%20Key%2022.5%5D%20-%20%5BSectors%20of%20the%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "22",
            chapterName: "Sectors of the Indian Economy",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter22/casestudyquestion.com%20-%20%5BAnswer%20Key%2022.6%5D%20-%20%5BSectors%20of%20the%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "22",
            chapterName: "Sectors of the Indian Economy",
            setInd: "7 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter22/casestudyquestion.com%20-%20%5BAnswer%20Key%2022.7%5D%20-%20%5BSectors%20of%20the%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "22",
            chapterName: "Sectors of the Indian Economy",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter22/casestudyquestion.com%20-%20%5BWorksheet%2022.1%5D%20-%20%5BSectors%20of%20the%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "22",
            chapterName: "Sectors of the Indian Economy",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter22/casestudyquestion.com%20-%20%5BWorksheet%2022.2%5D%20-%20%5BSectors%20of%20the%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "22",
            chapterName: "Sectors of the Indian Economy",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter22/casestudyquestion.com%20-%20%5BWorksheet%2022.3%5D%20-%20%5BSectors%20of%20the%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "22",
            chapterName: "Sectors of the Indian Economy",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter22/casestudyquestion.com%20-%20%5BWorksheet%2022.4%5D%20-%20%5BSectors%20of%20the%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "22",
            chapterName: "Sectors of the Indian Economy",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter22/casestudyquestion.com%20-%20%5BWorksheet%2022.5%5D%20-%20%5BSectors%20of%20the%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "22",
            chapterName: "Sectors of the Indian Economy",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter22/casestudyquestion.com%20-%20%5BWorksheet%2022.6%5D%20-%20%5BSectors%20of%20the%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "22",
            chapterName: "Sectors of the Indian Economy",
            setInd: "7 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter22/casestudyquestion.com%20-%20%5BWorksheet%2022.7%5D%20-%20%5BSectors%20of%20the%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "23",
            chapterName: "Money and Credit",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter23/casestudyquestion.com%20-%20%5BAnswer%20Key%2023.1%5D%20-%20%5BMoney%20and%20Credit%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "23",
            chapterName: "Money and Credit",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter23/casestudyquestion.com%20-%20%5BAnswer%20Key%2023.2%5D%20-%20%5BMoney%20and%20Credit%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "23",
            chapterName: "Money and Credit",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter23/casestudyquestion.com%20-%20%5BAnswer%20Key%2023.3%5D%20-%20%5BMoney%20and%20Credit%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "23",
            chapterName: "Money and Credit",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter23/casestudyquestion.com%20-%20%5BAnswer%20Key%2023.4%5D%20-%20%5BMoney%20and%20Credit%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "23",
            chapterName: "Money and Credit",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter23/casestudyquestion.com%20-%20%5BAnswer%20Key%2023.5%5D%20-%20%5BMoney%20and%20Credit%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "23",
            chapterName: "Money and Credit",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter23/casestudyquestion.com%20-%20%5BWorksheet%2023.1%5D%20-%20%5BMoney%20and%20Credit%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "23",
            chapterName: "Money and Credit",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter23/casestudyquestion.com%20-%20%5BWorksheet%2023.2%5D%20-%20%5BMoney%20and%20Credit%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "23",
            chapterName: "Money and Credit",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter23/casestudyquestion.com%20-%20%5BWorksheet%2023.3%5D%20-%20%5BMoney%20and%20Credit%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "23",
            chapterName: "Money and Credit",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter23/casestudyquestion.com%20-%20%5BWorksheet%2023.4%5D%20-%20%5BMoney%20and%20Credit%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "23",
            chapterName: "Money and Credit",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter23/casestudyquestion.com%20-%20%5BWorksheet%2023.5%5D%20-%20%5BMoney%20and%20Credit%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "24",
            chapterName: "Globalisation and The Indian Economy",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter24/casestudyquestion.com%20-%20%5BAnswer%20Key%2024.1%5D%20-%20%5BGlobalisation%20and%20The%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "24",
            chapterName: "Globalisation and The Indian Economy",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter24/casestudyquestion.com%20-%20%5BAnswer%20Key%2024.2%5D%20-%20%5BGlobalisation%20and%20The%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "24",
            chapterName: "Globalisation and The Indian Economy",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter24/casestudyquestion.com%20-%20%5BAnswer%20Key%2024.3%5D%20-%20%5BGlobalisation%20and%20The%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "24",
            chapterName: "Globalisation and The Indian Economy",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter24/casestudyquestion.com%20-%20%5BAnswer%20Key%2024.4%5D%20-%20%5BGlobalisation%20and%20The%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "24",
            chapterName: "Globalisation and The Indian Economy",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter24/casestudyquestion.com%20-%20%5BAnswer%20Key%2024.5%5D%20-%20%5BGlobalisation%20and%20The%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "24",
            chapterName: "Globalisation and The Indian Economy",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter24/casestudyquestion.com%20-%20%5BAnswer%20Key%2024.6%5D%20-%20%5BGlobalisation%20and%20The%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "24",
            chapterName: "Globalisation and The Indian Economy",
            setInd: "7 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter24/casestudyquestion.com%20-%20%5BAnswer%20Key%2024.7%5D%20-%20%5BGlobalisation%20and%20The%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "24",
            chapterName: "Globalisation and The Indian Economy",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter24/casestudyquestion.com%20-%20%5BWorksheet%2024.1%5D%20-%20%5BGlobalisation%20and%20The%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "24",
            chapterName: "Globalisation and The Indian Economy",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter24/casestudyquestion.com%20-%20%5BWorksheet%2024.2%5D%20-%20%5BGlobalisation%20and%20The%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "24",
            chapterName: "Globalisation and The Indian Economy",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter24/casestudyquestion.com%20-%20%5BWorksheet%2024.3%5D%20-%20%5BGlobalisation%20and%20The%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "24",
            chapterName: "Globalisation and The Indian Economy",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter24/casestudyquestion.com%20-%20%5BWorksheet%2024.4%5D%20-%20%5BGlobalisation%20and%20The%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "24",
            chapterName: "Globalisation and The Indian Economy",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter24/casestudyquestion.com%20-%20%5BWorksheet%2024.5%5D%20-%20%5BGlobalisation%20and%20The%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "24",
            chapterName: "Globalisation and The Indian Economy",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter24/casestudyquestion.com%20-%20%5BWorksheet%2024.6%5D%20-%20%5BGlobalisation%20and%20The%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "24",
            chapterName: "Globalisation and The Indian Economy",
            setInd: "7 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter24/casestudyquestion.com%20-%20%5BWorksheet%2024.7%5D%20-%20%5BGlobalisation%20and%20The%20Indian%20Economy%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "25",
            chapterName: "Consumer Rights",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter25/casestudyquestion.com%20-%20%5BAnswer%20Key%2025.1%5D%20-%20%5BConsumer%20Rights%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "25",
            chapterName: "Consumer Rights",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter25/casestudyquestion.com%20-%20%5BWorksheet%2025.1%5D%20-%20%5BConsumer%20Rights%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "26",
            chapterName: "CBSE Sample Paper 2021",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter26/casestudyquestion.com%20-%20%5BAnswer%20Key%2026.1%5D%20-%20%5BCBSE%20Sample%20Paper%20%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "26",
            chapterName: "CBSE Sample Paper 2021",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter26/casestudyquestion.com%20-%20%5BAnswer%20Key%2026.2%5D%20-%20%5BCBSE%20Sample%20Paper%20%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "26",
            chapterName: "CBSE Sample Paper 2021",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter26/casestudyquestion.com%20-%20%5BWorksheet%2026.1%5D%20-%20%5BCBSE%20Sample%20Paper%20%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "26",
            chapterName: "CBSE Sample Paper 2021",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter26/casestudyquestion.com%20-%20%5BWorksheet%2026.2%5D%20-%20%5BCBSE%20Sample%20Paper%20%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "3",
            chapterName: "The Making of a Global World",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter3/casestudyquestion.com%20-%20%5BAnswer%20Key%203.1%5D%20-%20%5BThe%20Making%20of%20a%20Global%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "3",
            chapterName: "The Making of a Global World",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter3/casestudyquestion.com%20-%20%5BAnswer%20Key%203.2%5D%20-%20%5BThe%20Making%20of%20a%20Global%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "3",
            chapterName: "The Making of a Global World",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter3/casestudyquestion.com%20-%20%5BAnswer%20Key%203.3%5D%20-%20%5BThe%20Making%20of%20a%20Global%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "3",
            chapterName: "The Making of a Global World",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter3/casestudyquestion.com%20-%20%5BAnswer%20Key%203.4%5D%20-%20%5BThe%20Making%20of%20a%20Global%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "3",
            chapterName: "The Making of a Global World",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter3/casestudyquestion.com%20-%20%5BAnswer%20Key%203.5%5D%20-%20%5BThe%20Making%20of%20a%20Global%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "3",
            chapterName: "The Making of a Global World",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter3/casestudyquestion.com%20-%20%5BAnswer%20Key%203.6%5D%20-%20%5BThe%20Making%20of%20a%20Global%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "3",
            chapterName: "The Making of a Global World",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter3/casestudyquestion.com%20-%20%5BWorksheet%203.1%5D%20-%20%5BThe%20Making%20of%20a%20Global%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "3",
            chapterName: "The Making of a Global World",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter3/casestudyquestion.com%20-%20%5BWorksheet%203.2%5D%20-%20%5BThe%20Making%20of%20a%20Global%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "3",
            chapterName: "The Making of a Global World",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter3/casestudyquestion.com%20-%20%5BWorksheet%203.3%5D%20-%20%5BThe%20Making%20of%20a%20Global%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "3",
            chapterName: "The Making of a Global World",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter3/casestudyquestion.com%20-%20%5BWorksheet%203.4%5D%20-%20%5BThe%20Making%20of%20a%20Global%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "3",
            chapterName: "The Making of a Global World",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter3/casestudyquestion.com%20-%20%5BWorksheet%203.5%5D%20-%20%5BThe%20Making%20of%20a%20Global%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "3",
            chapterName: "The Making of a Global World",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter3/casestudyquestion.com%20-%20%5BWorksheet%203.6%5D%20-%20%5BThe%20Making%20of%20a%20Global%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "4",
            chapterName: "The Age of Industrialization",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter4/casestudyquestion.com%20-%20%5BAnswer%20Key%204.1%5D%20-%20%5BThe%20Age%20of%20Industrialization%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "4",
            chapterName: "The Age of Industrialization",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter4/casestudyquestion.com%20-%20%5BAnswer%20Key%204.2%5D%20-%20%5BThe%20Age%20of%20Industrialization%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "4",
            chapterName: "The Age of Industrialization",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter4/casestudyquestion.com%20-%20%5BAnswer%20Key%204.3%5D%20-%20%5BThe%20Age%20of%20Industrialization%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "4",
            chapterName: "The Age of Industrialization",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter4/casestudyquestion.com%20-%20%5BAnswer%20Key%204.4%5D%20-%20%5BThe%20Age%20of%20Industrialization%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "4",
            chapterName: "The Age of Industrialization",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter4/casestudyquestion.com%20-%20%5BAnswer%20Key%204.5%5D%20-%20%5BThe%20Age%20of%20Industrialization%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "4",
            chapterName: "The Age of Industrialization",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter4/casestudyquestion.com%20-%20%5BAnswer%20Key%204.6%5D%20-%20%5BThe%20Age%20of%20Industrialization%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "4",
            chapterName: "The Age of Industrialization",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter4/casestudyquestion.com%20-%20%5BWorksheet%204.1%5D%20-%20%5BThe%20Age%20of%20Industrialization%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "4",
            chapterName: "The Age of Industrialization",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter4/casestudyquestion.com%20-%20%5BWorksheet%204.2%5D%20-%20%5BThe%20Age%20of%20Industrialization%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "4",
            chapterName: "The Age of Industrialization",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter4/casestudyquestion.com%20-%20%5BWorksheet%204.3%5D%20-%20%5BThe%20Age%20of%20Industrialization%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "4",
            chapterName: "The Age of Industrialization",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter4/casestudyquestion.com%20-%20%5BWorksheet%204.4%5D%20-%20%5BThe%20Age%20of%20Industrialization%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "4",
            chapterName: "The Age of Industrialization",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter4/casestudyquestion.com%20-%20%5BWorksheet%204.5%5D%20-%20%5BThe%20Age%20of%20Industrialization%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "4",
            chapterName: "The Age of Industrialization",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter4/casestudyquestion.com%20-%20%5BWorksheet%204.6%5D%20-%20%5BThe%20Age%20of%20Industrialization%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "5",
            chapterName: "Print Culture and the Modern World",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.1%5D%20-%20%5BPrint%20Culture%20and%20the%20Modern%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "5",
            chapterName: "Print Culture and the Modern World",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.2%5D%20-%20%5BPrint%20Culture%20and%20the%20Modern%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "5",
            chapterName: "Print Culture and the Modern World",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.3%5D%20-%20%5BPrint%20Culture%20and%20the%20Modern%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "5",
            chapterName: "Print Culture and the Modern World",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.4%5D%20-%20%5BPrint%20Culture%20and%20the%20Modern%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "5",
            chapterName: "Print Culture and the Modern World",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.5%5D%20-%20%5BPrint%20Culture%20and%20the%20Modern%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "5",
            chapterName: "Print Culture and the Modern World",
            setInd: "6 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter5/casestudyquestion.com%20-%20%5BAnswer%20Key%205.6%5D%20-%20%5BPrint%20Culture%20and%20the%20Modern%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "5",
            chapterName: "Print Culture and the Modern World",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.1%5D%20-%20%5BPrint%20Culture%20and%20the%20Modern%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "5",
            chapterName: "Print Culture and the Modern World",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.2%5D%20-%20%5BPrint%20Culture%20and%20the%20Modern%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "5",
            chapterName: "Print Culture and the Modern World",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.3%5D%20-%20%5BPrint%20Culture%20and%20the%20Modern%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "5",
            chapterName: "Print Culture and the Modern World",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.4%5D%20-%20%5BPrint%20Culture%20and%20the%20Modern%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "5",
            chapterName: "Print Culture and the Modern World",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.5%5D%20-%20%5BPrint%20Culture%20and%20the%20Modern%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "5",
            chapterName: "Print Culture and the Modern World",
            setInd: "6 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter5/casestudyquestion.com%20-%20%5BWorksheet%205.6%5D%20-%20%5BPrint%20Culture%20and%20the%20Modern%20World%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "6",
            chapterName: "Resources and Development",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.1%5D%20-%20%5BResources%20and%20Development%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "6",
            chapterName: "Resources and Development",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.2%5D%20-%20%5BResources%20and%20Development%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "6",
            chapterName: "Resources and Development",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.3%5D%20-%20%5BResources%20and%20Development%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "6",
            chapterName: "Resources and Development",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.4%5D%20-%20%5BResources%20and%20Development%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "6",
            chapterName: "Resources and Development",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter6/casestudyquestion.com%20-%20%5BAnswer%20Key%206.5%5D%20-%20%5BResources%20and%20Development%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "6",
            chapterName: "Resources and Development",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.1%5D%20-%20%5BResources%20and%20Development%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "6",
            chapterName: "Resources and Development",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.2%5D%20-%20%5BResources%20and%20Development%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "6",
            chapterName: "Resources and Development",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.3%5D%20-%20%5BResources%20and%20Development%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "6",
            chapterName: "Resources and Development",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.4%5D%20-%20%5BResources%20and%20Development%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "6",
            chapterName: "Resources and Development",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter6/casestudyquestion.com%20-%20%5BWorksheet%206.5%5D%20-%20%5BResources%20and%20Development%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "7",
            chapterName: "Forest and Wildlife",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter7/casestudyquestion.com%20-%20%5BAnswer%20Key%207.1%5D%20-%20%5BForest%20and%20Wildlife%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "7",
            chapterName: "Forest and Wildlife",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter7/casestudyquestion.com%20-%20%5BWorksheet%207.1%5D%20-%20%5BForest%20and%20Wildlife%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "8",
            chapterName: "Water Resources",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter8/casestudyquestion.com%20-%20%5BAnswer%20Key%208.1%5D%20-%20%5BWater%20Resources%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "8",
            chapterName: "Water Resources",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter8/casestudyquestion.com%20-%20%5BWorksheet%208.1%5D%20-%20%5BWater%20Resources%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "9",
            chapterName: "Agriculture",
            setInd: "1 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.1%5D%20-%20%5BAgriculture%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "9",
            chapterName: "Agriculture",
            setInd: "2 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.2%5D%20-%20%5BAgriculture%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "9",
            chapterName: "Agriculture",
            setInd: "3 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.3%5D%20-%20%5BAgriculture%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "9",
            chapterName: "Agriculture",
            setInd: "4 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.4%5D%20-%20%5BAgriculture%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "9",
            chapterName: "Agriculture",
            setInd: "5 ",
            type: "AnswerKey",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter9/casestudyquestion.com%20-%20%5BAnswer%20Key%209.5%5D%20-%20%5BAgriculture%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "9",
            chapterName: "Agriculture",
            setInd: "1 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.1%5D%20-%20%5BAgriculture%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "9",
            chapterName: "Agriculture",
            setInd: "2 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.2%5D%20-%20%5BAgriculture%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "9",
            chapterName: "Agriculture",
            setInd: "3 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.3%5D%20-%20%5BAgriculture%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "9",
            chapterName: "Agriculture",
            setInd: "4 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 2,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.4%5D%20-%20%5BAgriculture%5D.pdf",
        },
        {
            subject: "Social Studies",
            chapterInd: "9",
            chapterName: "Agriculture",
            setInd: "5 ",
            type: "Worksheet",
            setMetaData: {
                setData: [
                    {
                        noOfQuestions: 4,
                    },
                ],
                noOfPassage: 1,
            },
            link:
                "https://worksheet-pdf-dev.s3.ap-south-1.amazonaws.com/Social%20Studies/chapter9/casestudyquestion.com%20-%20%5BWorksheet%209.5%5D%20-%20%5BAgriculture%5D.pdf",
        },
    ],
};
