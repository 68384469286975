import { FSStoreInstance } from "../stores/FSstore";

const checkIfCanDownload = (downloadCount, loggedIn, shared, noBlocker) => {
    if (noBlocker) {
        return true;
    }

    let loginBlockerAtCount = 1;
    let shareBlockerAtCount = 3;

    if (FSStoreInstance.ConfigValues !== undefined) {
        loginBlockerAtCount = FSStoreInstance.ConfigValues.paramsList.filter(
            (inst) => inst.desc === "loginBlockerAtCount"
        )[0].value;
        shareBlockerAtCount = FSStoreInstance.ConfigValues.paramsList.filter(
            (inst) => inst.desc === "shareBlockerAtCount"
        )[0].value;

        //console.log(loginBlockerAtCount, shareBlockerAtCount);
    }

    if (!loggedIn) {
        if (downloadCount >= loginBlockerAtCount) {
            return false;
        } else {
            return true;
        }
    } else {
        if (!shared && downloadCount >= shareBlockerAtCount) {
            return false;
        } else {
            return true;
        }
    }
};

export default checkIfCanDownload;
